import styled, { css } from "styled-components";
import { FlexColumn, FlexRow, H4, Small, Footnote, Icon } from "notes";
import { useTimeContext } from "Context/TimeContext";
import { useArtistEventContext } from "../Components";
import { buildDurationMessage, InfoCard } from "Components";
import { Title } from "./Dashboard";

export const Support = () => {
  const { requests: rawRequests } = useArtistEventContext();
  const { time } = useTimeContext();

  const requests = rawRequests?.filter((req) => req.amount > 0);

  return (
    <Wrapper>
      <Title style={{ marginBottom: !!requests?.length ? "8px" : 0 }}>
        <H4>Support</H4>
      </Title>
      {!!requests?.length ? (
        requests?.map(
          ({ amount, createdAt, songName, displayName, userTotal, _id }) => {
            const durationMessage = buildDurationMessage(time, createdAt);
            return (
              <RequestCard yEnd key={_id}>
                <FlexRow xEnd>
                  <FanName isDonation={!songName}>
                    {displayName}
                    {!songName && <Icon tertiary name="Charity" />}
                  </FanName>
                  {amount > 0 && (
                    <H4 style={{ fontWeight: 900 }}>${amount / 100}</H4>
                  )}
                </FlexRow>
                <FlexRow yEnd style={{ justifyContent: "space-between" }}>
                  <Small>
                    {durationMessage}• {songName ?? "Donation"}
                  </Small>
                  {userTotal > 0 && (
                    <Footnote>
                      Total support
                      <span>${userTotal / 100}</span>
                    </Footnote>
                  )}
                </FlexRow>
              </RequestCard>
            );
          }
        )
      ) : (
        <Small>No support has been given at this time.</Small>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  width: 100%;
  background: #f7fafc;
  padding: 16px 16px 68px 16px;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
`;

const FanName = styled(H4)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 17px;
  line-height: 22px;
  font-weight: 800;
  svg {
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }
  ${(props) =>
    props.isDonation &&
    css`
      color: ${(props) => props.theme.palette.teal.primary};
    `};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 25px;
    font-weight: 600;
    line-height: 32px;
    svg {
      width: 20px;
      height: 20px;
      margin-left: 12px;
    }
  }
`;

const RequestCard = styled(InfoCard)`
  padding: 12px;
  & + & {
    margin-top: 8px;
  }
  ${FlexRow} {
    width: 100%;
  }
  ${Small} {
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ${Footnote} {
    white-space: nowrap;
    margin-left: 12px;
    span {
      font-weight: 600;
      margin-left: 4px;
    }
  }
  ${Footnote}, ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    line-height: 15px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    padding: 12px 16px;
    ${H4} {
      font-size: 22px;
      line-height: 26px;
    }
    ${Footnote}, ${Small} {
      color: ${(props) => props.theme.palette.gray.primary};
      font-size: 15px;
      line-height: 19px;
    }
  }
`;
