import styled from "styled-components";
import {
  Button,
  FlexColumn,
  FlexRow,
  Icon,
  Small,
  H3,
  H4,
  Subheader,
} from "notes";
import { IconQRCode } from "Images";
import { useState } from "react";
import { useArtistEventContext, useArtistModalContext } from "../Components";
import { InfoCard } from "Components";

export const Dashboard = () => {
  const { event, requests } = useArtistEventContext();
  const { setModal } = useArtistModalContext();
  const [copyIndicatorIsVisible, setCopyIndicatorIsVisible] = useState(false);
  const updatedRequests = requests?.reduce(function (map, obj) {
    map[obj.uid] = map[obj.uid]
      ? { ...map[obj.uid], userTotal: map[obj.uid].userTotal + obj.amount }
      : { ...obj, userTotal: obj.amount };
    return map;
  }, {});

  const sortedRequests = Object.entries<any>(updatedRequests)
    ?.map(([id, object]) => ({
      ...object,
      uid: id,
    }))
    ?.sort((a, b) => (a.userTotal > b.userTotal ? -1 : 1));

  const isSponsorEvent = event?.type === "sponsor";

  const donations = requests?.filter((req) => req.type === "donate");
  const donationsAmount = donations?.reduce((a, b) => a + b.amount, 0);
  const votes = requests?.filter(
    (req) => req.type === (isSponsorEvent ? "sponsor" : "vote")
  );
  const votesAmount = votes?.reduce((a, b) => a + b.amount, 0);
  const encoreVotes = requests?.filter((req) => req.type === "encore");
  const entries = requests?.filter((req) => req.amount === 0);
  const encoreAmount = encoreVotes?.reduce((a, b) => a + b.amount, 0);

  const onCopyToClipboard = () => {
    setCopyIndicatorIsVisible(true);
    setTimeout(() => {
      setCopyIndicatorIsVisible(false);
    }, 2300);
  };

  return (
    <SectionDashboard>
      <Title>
        <H4>Dashboard</H4>
      </Title>
      <CardSection>
        {event?.type === "checkIn" && (
          <FlexColumn>
            <Subtitle>Fan Entries</Subtitle>
            <FlexRow>
              <StyledCard>
                <H4>{entries?.length}</H4>
                <Subheader>
                  {entries?.length === 1 ? "Entry" : "Entries"}
                </Subheader>
              </StyledCard>
            </FlexRow>
          </FlexColumn>
        )}
        {!["checkIn", "encore"].includes(event?.type) && (
          <FlexColumn>
            <Subtitle>Support</Subtitle>
            <FlexRow>
              <StyledCard>
                <H4>${votesAmount / 100}</H4>
                <Subheader>
                  {votes.length} {isSponsorEvent ? "Sponsors" : "Song Votes"}
                </Subheader>
              </StyledCard>
              <StyledCard>
                <H4>${donationsAmount / 100}</H4>
                <Subheader>{donations.length} Donations</Subheader>
              </StyledCard>
            </FlexRow>
          </FlexColumn>
        )}
        {event?.encore && (
          <FlexRow
            style={{ marginTop: event?.type !== "encore" ? "16px" : "0" }}
          >
            <StyledCard>
              <H4>{encoreVotes?.length}</H4>
              <Subheader>Encore Votes</Subheader>
            </StyledCard>
            {event?.encore?.minVoteAmount > 0 && (
              <StyledCard>
                <H4>${encoreAmount / 100}</H4>
                <Subheader>Encore Total</Subheader>
              </StyledCard>
            )}
          </FlexRow>
        )}
        {event?.type === "checkIn" ||
        (event?.type === "encore" && event?.encore?.minVoteAmount === 0) ? (
          <FlexColumn flex="0 0 32px" />
        ) : (
          <TopFans yCenter>
            <Subtitle>Top Fans</Subtitle>
            <PillContainer>
              {sortedRequests.length > 0 ? (
                sortedRequests
                  ?.map(({ displayName, userTotal }, index) => {
                    return (
                      <Pill>
                        <TagNumber>{index + 1}.</TagNumber> {displayName} - $
                        {userTotal / 100}
                      </Pill>
                    );
                  })
                  .slice(0, 5)
              ) : (
                <Small style={{ marginLeft: "4px" }}>
                  No{" "}
                  {event.type === "sponsor"
                    ? "sponsorships"
                    : event.type === "encore"
                    ? "votes"
                    : "requests"}{" "}
                  have been made.
                </Small>
              )}
            </PillContainer>
          </TopFans>
        )}
        <Subtitle>Shortcuts</Subtitle>
        <FlexRow>
          <ManageCard style={{ marginBottom: "32px" }}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              href={`/qr/${event?._id}`}
            >
              <IconQRCode />
              <span>Print QR</span>
            </Link>
            <MenuButton
              onClick={() =>
                setModal({
                  qrView: `${window.location.origin}/event/${event?._id}`,
                })
              }
            >
              <IconQRCode />
              <span>View QR</span>
            </MenuButton>
            <MenuButton
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/event/${event?._id}`
                );
                onCopyToClipboard();
              }}
            >
              <LinkIcon name="Link" />
              <span>Event Link</span>
            </MenuButton>
          </ManageCard>
        </FlexRow>
      </CardSection>
      <ButtonContainer>
        <Button
          type="destroy"
          onClick={() => setModal({ endEvent: event?._id })}
        >
          End Show
        </Button>
      </ButtonContainer>
      {copyIndicatorIsVisible && (
        <CopyIndicator onClick={() => setCopyIndicatorIsVisible(false)}>
          Copied to clipboard
          <Icon platform name="Close" />
        </CopyIndicator>
      )}
    </SectionDashboard>
  );
};

export const Title = styled(FlexRow)`
  margin-bottom: 16px;
  display: flex;
  ${H4} {
    flex-grow: 1;
    font-weight: 700;
  }
`;

const PillContainer = styled(FlexRow)`
  margin-left: -4px;
  margin-right: -4px;
`;

const TopFans = styled(FlexColumn)`
  padding: 32px 0;
  & > ${FlexRow} {
    flex-wrap: wrap;
  }
`;

const Subtitle = styled(H3)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-family: ${(props) => props.theme.branding.bodyFont};
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const StyledCard = styled(InfoCard)`
  padding: 16px;
  position: relative;
  text-align: center;
  width: 100%;
  ${H4} {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 8px;
    @media only screen and ${(props) => props.theme.media.medium} {
      font-size: 25px;
      line-height: 32px;
    }
  }
  ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin-top: 8px;
  }
  ${Subheader} {
    color: ${(props) => props.theme.palette.gray.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    width: 100%;
    svg {
      margin-right: 4px;
    }
    @media only screen and ${(props) => props.theme.media.medium} {
      font-size: 18px;
      line-height: 22px;
    }
  }
  & + & {
    margin-left: 16px;
  }
`;

const SectionDashboard = styled(FlexColumn)`
  background: #f7fafc;
  padding: 16px 16px 80px 16px;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
`;

const CardSection = styled(FlexColumn)`
  padding: 0;
`;

export const ManageCard = styled(StyledCard)`
  flex-direction: row;
  justify-content: space-around;
`;

export const MenuButton = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  width: 80px;
  padding: 6px;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.gray.primary};
  svg {
    path {
      fill: ${(props) => props.theme.palette.gray.primary};
    }
  }
  span {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 600;
  }
`;

const ButtonContainer = styled(FlexRow)`
  margin-top: 24px;
  padding: 0 16px;
  justify-content: flex-end;
  button {
    background-color: ${(props) => props.theme.palette.red.primary};
    border-radius: 20px;
    color: #ffffff;
    min-width: 164px;
    width: 164px;
  }
`;
const Link = styled(MenuButton).attrs({ as: "a" })``;

const LinkIcon = styled(Icon)`
  width: 22px;
  margin: -4px;
`;

const Pill = styled(FlexRow)`
  border-radius: 24px;
  padding: 6px 12px;
  background-color: ${(props) => props.theme.palette.teal.lightest};
  margin: 0 4px 8px 4px;
  color: ${({ theme }) => theme.palette.teal.primary};
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 25px;
    line-height: 32px;
  }
`;

const TagNumber = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 6px;
`;

export const CopyIndicator = styled(FlexRow)`
  align-items: center;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.8);
  height: 44px;
  padding: 0 16px 0 12px;
  position: fixed;
  bottom: 75px;
  left: 12px;
  font-size: 15px;
  color: #ffffff;
  z-index: 9999;
  svg {
    margin-left: 20px;
    width: 15px;
    height: 15px;
    path {
      fill: #ffffff;
    }
  }
`;
