import styled from "styled-components";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
type ShareUrls = nextsong.firestore.Bio["shareUrls"];
type SocialColorName = nextsong.firestore.SocialColorName;

interface SocialLinks {
  urls: ShareUrls;
  color?: SocialColorName;
}

const services = [
  "amazon",
  "apple",
  "bandsintown",
  "deezer",
  "discord",
  "facebook",
  "instagram",
  "messenger",
  "pandora",
  "pinterest",
  "reddit",
  "snapchat",
  "soundcloud",
  "spotify",
  "tidal",
  "tiktok",
  "twitter",
  "youtube",
];

// Streaming service icons
export const buildSocialLinks = (social: SocialLinks) => {
  if (!social?.urls) {
    return null;
  }

  const color = social.color || "default";

  const links = social.urls?.map((url, index) => {
    const serviceName = services.find((service) => url.includes(service));
    if (!serviceName) {
      return null;
    }
    const iconSource = `${process.env.REACT_APP_ASSETS_PATH}/social_icons/${color}/${serviceName}.png`;
    return (
      <ServiceLink
        color={color}
        href={url}
        target="_blank"
        rel="noreferrer"
        key={`service-link${index}`}
      >
        <Icon src={iconSource} />
      </ServiceLink>
    );
  });
  return links;
};

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const ServiceLink = styled.a<{ color: SocialColorName }>`
  outline: none;
  display: inline-block;
  & + & {
    margin-left: 12px;
  }
`;
