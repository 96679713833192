import styled, { css } from "styled-components";
import { FlexColumn, FlexRow, H4, Small, Footnote } from "notes";
import { useTimeContext } from "Context/TimeContext";
import { useArtistEventContext } from "../Components";
import { buildDurationMessage, InfoCard } from "Components";
import { defaultLeadFields } from "Routes/Fan/Components/defaultFormContent";
import { Title } from "./Dashboard";

export const Leads = () => {
  const { requests, event } = useArtistEventContext();
  const { time } = useTimeContext();

  const form = event?.checkIn?.form ?? defaultLeadFields;
  const isCheckIn = event?.type === "checkIn";
  const highlightFields = form?.filter(({ highlight }) => highlight);
  const updatedRequests = requests
    ?.filter(({ amount }) => amount === 0)
    .map((req) => ({ ...req, ...req?.extraFields }));

  return (
    <Wrapper>
      <Title style={{ marginBottom: !!updatedRequests?.length ? "8px" : 0 }}>
        <H4>Entries</H4>
      </Title>
      {!!updatedRequests?.length ? (
        updatedRequests?.map((req) => {
          const { songName, displayName, createdAt, location, _id } = req;
          const durationMessage = buildDurationMessage(time, createdAt);
          const locationMessage = (
            <LocationMessage>from {location}</LocationMessage>
          );
          const trimmedName = displayName?.replace(/^\s+|\s+$/gm, "");
          const fanNameSplit = trimmedName?.split(" ");
          const fanName =
            fanNameSplit?.length > 2
              ? `${fanNameSplit[0]} ${fanNameSplit[1]}`
              : `${fanNameSplit[0]} ${
                  !!fanNameSplit[1] ? fanNameSplit[1]?.charAt(0) : ""
                }`;

          const extraFields = highlightFields
            ?.map(({ name, label }) =>
              !!req[name] ? (
                <FieldHighlight>
                  <Small>{label}</Small>
                  <HeadlineText highlight>{req[name]}</HeadlineText>
                </FieldHighlight>
              ) : null
            )
            .filter((field) => field);

          return (
            <RequestCard yEnd key={_id}>
              <FlexRow xEnd>
                <Headline>
                  {!extraFields?.length ? ( // check for any highlight fields
                    // no highlighted fields
                    isCheckIn ? (
                      // checkIn event type, no voting, display name & location
                      <FlexRow yEnd>
                        <HeadlineText style={{ marginBottom: "1px" }}>
                          {fanName}
                        </HeadlineText>
                        {location && locationMessage}
                      </FlexRow>
                    ) : (
                      // if not checkIn, event has voting. Display vote value.
                      <HeadlineText>{songName}</HeadlineText>
                    )
                  ) : (
                    // event has highlighted fields, so display these instead.
                    extraFields
                  )}
                </Headline>
              </FlexRow>
              <Subheadline yEnd>
                <Small>
                  {isCheckIn && !extraFields?.length ? (
                    durationMessage
                  ) : (
                    <>
                      <span>{fanName}</span>
                      {location && locationMessage}
                    </>
                  )}
                </Small>
              </Subheadline>
            </RequestCard>
          );
        })
      ) : (
        <Small>There are no entries at this time.</Small>
      )}
    </Wrapper>
  );
};

const Subheadline = styled(FlexRow)`
  justify-content: space-between;
  ${Small} {
    display: flex;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      font-weight: 600;
    }
  }
`;

const FieldHighlight = styled(FlexColumn)`
  margin-bottom: 8px;
`;

const LocationMessage = styled.div`
  display: flex;
  line-height: 15px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.gray.primary};
  margin-bottom: 3px;
  margin-left: 4px;
`;

const Wrapper = styled(FlexColumn)`
  background: #f7fafc;
  padding: 16px 16px 68px 16px;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
`;

const HeadlineText = styled(H4)`
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  ${(props) =>
    props.highlight &&
    css`
      color: ${(props) => props.theme.palette.teal.primary};
    `};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 25px;
    font-weight: 600;
    line-height: 32px;
  }
`;

const Headline = styled(FlexColumn)`
  flex-grow: 1;
  ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-weight: 600;
  }
  svg {
    width: 14px;
    height: 14px;
    margin-left: 6px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    svg {
      width: 20px;
      height: 20px;
      margin-left: 12px;
    }
  }
`;

const RequestCard = styled(InfoCard)`
  padding: 12px;
  & + & {
    margin-top: 8px;
  }
  & > ${FlexRow} {
    width: 100%;
    ${Footnote}, & > ${Small} {
      color: ${(props) => props.theme.palette.gray.primary};
      font-size: 12px;
      line-height: 15px;
    }
  }
  ${Footnote} {
    white-space: nowrap;
    margin-left: 12px;
    span {
      font-weight: 600;
      margin-left: 4px;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    ${H4} {
      font-size: 25px;
      line-height: 32px;
    }
    & > ${FlexRow} {
      ${Footnote}, & > ${Small} {
        color: ${(props) => props.theme.palette.gray.primary};
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
`;
