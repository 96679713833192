import _ from "lodash";

export const assetPath =
  process.env.REACT_APP_STAGE === "production"
    ? "https://set.live/assets/nextsong"
    : "https://stage.set.live/assets/nextsong";

export const directPath =
  process.env.REACT_APP_STAGE === "production"
    ? "https://storage.googleapis.com/set-live.appspot.com/nextsong"
    : "https://storage.googleapis.com/set-live-stage.appspot.com/nextsong";

export const formatCurrency = (amount = 0) => {
  const newAmount = amount / 100;
  return `$${newAmount}.00`;
};

export const getDateOrdinal = (d) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const getTime = (s) => {
  const timeToString = (v) => (v >= 10 ? `${v}` : `0${v}`);

  const m = s / 60;
  let hours = Math.floor(m / 60);
  const minutes = m % 60;
  let isAm = true;
  if (hours >= 12) isAm = false;
  if (hours > 12) {
    hours -= 12;
  }

  return `${hours === 0 ? 12 : hours}:${timeToString(Math.floor(minutes))} ${
    isAm ? "AM" : "PM"
  }`;
};

export const timeIncrements = (() => {
  const res = [];
  for (let m = 0; m < 1440; m += 15)
    res.push({
      value: getTime(m * 60),
    });
  return res;
})();

export const validate = (data, schema) => {
  const { error } = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
  });

  let errors = null;

  _.get(error, "details", []).forEach(({ message, path, type, context }) => {
    const { key } = context;

    if (!errors || !errors[key]) {
      if (!errors) {
        errors = {};
      }
      switch (type) {
        case "any.empty":
          errors[key] = `"${key}" cannot be blank.`;
          break;
        default:
          errors[key] = message;
          break;
      }
    }
  });

  return errors;
};

export const textCompare = (value1, value2, searchValue) => {
  const lowerCase1 = value1?.toLowerCase();
  const lowerCase2 = value2?.toLowerCase();
  const lowerCaseSearch = searchValue?.toLowerCase();
  const formattedValue1 = lowerCase1?.replace(/[^\w ]/g, "");
  const formattedValue2 = lowerCase2?.replace(/[^\w ]/g, "");
  const firstCompare =
    lowerCase1?.includes(lowerCaseSearch) ||
    lowerCase2?.includes(lowerCaseSearch);
  const secondCompare =
    formattedValue1?.includes(lowerCaseSearch) ||
    formattedValue2?.includes(lowerCaseSearch);
  if (firstCompare || secondCompare) {
    return true;
  } else {
    return false;
  }
};

export const adjustColor = (color: string, amount: number) => {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).slice(-2)
      )
  );
};

export const getLuminance = (values) => {
  const rgb = values.map((v) => {
    const val = v / 255;
    return val <= 0.03928 ? val / 12.92 : ((val + 0.055) / 1.055) ** 2.4;
  });
  return Number(
    (0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]).toFixed(3)
  );
};

export const getContrastRatio = (colorA, colorB) => {
  const lumA = getLuminance(colorA);
  const lumB = getLuminance(colorB);

  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05);
};

export const hexToRgb = (hex) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};
