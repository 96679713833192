import styled from "styled-components";
import { FlexColumn, H3, Subheader, Icon, Footnote, FlexRow, P } from "notes";
import {
  useFanEventContext,
  useFanModalContext,
  ActionButton,
} from "./Components";
import { PercentageBar, PercentageWrapper } from "./Requests";
import {
  SongWrapper,
  ListWrapper as ListWrapperBase,
  ArtistName,
} from "./Playlist";
import { useTimeContext } from "Context";
import { useAnalytics } from "Hooks";

export const Encore = () => {
  const {
    event,
    playlist,
    myRequests,
    topVoteRequestAccumAmount,
    encorePaymentAllowed,
  } = useFanEventContext();
  const { logClick } = useAnalytics();
  const { setModalState } = useFanModalContext();
  const { time } = useTimeContext();

  const encoreDuration: number = event?.encore?.endOffset ?? 20;

  const isEncoreClosed: boolean =
    time > event?.startsAt?.plus({ minutes: encoreDuration });

  const songs = playlist?.filter(
    ({ encore, playedAt }) => !!encore && !playedAt?.length
  );

  const handleClick = (songId: string) => {
    logClick({
      action: "open_encore_modal",
      eventId: event._id,
    });
    if (encorePaymentAllowed) {
      setModalState({
        encoreModal: {
          songId,
        },
      });
    } else {
      setModalState({ leadCollection: { songId } });
    }
  };

  const compareSongPriority = (a, b) => {
    const valueA = encorePaymentAllowed ? a.accumAmount : a.requests?.length;
    const valueB = encorePaymentAllowed ? b.accumAmount : b.requests?.length;
    return valueB - valueA;
  };

  const overrides = event?.customizations?.encore;
  const showResults = !!overrides?.enableFanResults;

  const sortedSongs =
    isEncoreClosed && showResults
      ? songs?.sort((a, b) => compareSongPriority(a, b))
      : songs?.sort((a, b) => a.title.localeCompare(b.title));

  const encoreRequestsMax = Math.max.apply(
    Math,
    sortedSongs?.map((item) => item.requests?.length ?? 0)
  );
  return (
    <ListWrapper>
      <FlexColumn>
        <H3>
          {event?.encore?.title ??
            event?.customizations?.encore?.title ??
            "Fan Favorite"}
        </H3>
        <Description>
          {event?.encore?.description ??
            event?.customizations?.encore?.description ??
            "Vote for the song you want to hear tonight!"}
        </Description>
        <Subheader>
          {event?.customizations?.encore?.caption ??
            event?.encore?.description2}
        </Subheader>
      </FlexColumn>
      <List>
        {sortedSongs?.map(
          ({ _id, title, artist, playedAt, accumAmount, requests }, index) => {
            const hasPlayed = playedAt?.length > 0;
            const isMyRequest = myRequests?.find(
              ({ songId }) => songId === _id
            );
            const amount = encorePaymentAllowed
              ? accumAmount
              : requests?.length ?? 0;
            const total = encorePaymentAllowed
              ? topVoteRequestAccumAmount
              : encoreRequestsMax;
            const calcPercent = (amount / total) * 100;
            const hasMaxRequests =
              !encorePaymentAllowed && !!myRequests?.length;
            return (
              <SongWrapper
                key={index}
                hasMaxRequests={
                  hasMaxRequests && !isMyRequest && !isEncoreClosed
                }
                style={{ flexDirection: "column" }}
              >
                <FlexRow yStart style={{ width: "100%" }}>
                  <Title yCenter>
                    <FlexRow>
                      <P>{title}</P>
                    </FlexRow>
                    <ArtistName>{artist}</ArtistName>
                  </Title>

                  <ActionButton
                    disabled={hasPlayed || hasMaxRequests || isEncoreClosed}
                    isReadOnly={hasMaxRequests || isEncoreClosed}
                    onClick={() => !hasPlayed && handleClick(_id)}
                  >
                    {!!hasPlayed ? (
                      "Played"
                    ) : !!isMyRequest ? (
                      encorePaymentAllowed ? (
                        <>
                          <Icon indicator name="Star" />
                          {overrides?.actionRepeatText ?? "Vote Again"}
                        </>
                      ) : (
                        overrides?.actionCompleteText ?? "Vote Cast"
                      )
                    ) : hasMaxRequests ? (
                      isEncoreClosed ? (
                        overrides?.actionDisabledText ?? "Vote Ended"
                      ) : (
                        ""
                      )
                    ) : isEncoreClosed ? (
                      overrides?.actionDisabledText ?? "Vote Ended"
                    ) : (
                      overrides?.actionText ?? "Vote"
                    )}
                  </ActionButton>
                </FlexRow>
                {isEncoreClosed && showResults && (
                  <PercentageWrapper style={{ margin: "8px 0 0 0" }}>
                    <PercentageBar percent={calcPercent} />
                  </PercentageWrapper>
                )}
              </SongWrapper>
            );
          }
        )}
      </List>
    </ListWrapper>
  );
};

const ListWrapper = styled(ListWrapperBase)`
  ${H3} {
    color: ${(props) => props.theme.branding.event.colors.title};
  }
  ${Subheader} {
    color: ${(props) => props.theme.branding.event.colors.caption};
  }
`;

const Description = styled(P)`
  color: ${(props) => props.theme.branding.event.colors.description};
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
`;

const List = styled(FlexColumn)`
  width: 100%;
`;

const Title = styled(FlexColumn)`
  flex-grow: 1;
  ${P} {
    color: ${(props) => props.theme.branding.event.colors.listTitle};
  }
  ${Footnote} {
    color: ${(props) => props.theme.branding.event.colors.listSubtitle};
    margin-left: 0;
    font-style: normal;
    font-weight: 600;
  }
`;
