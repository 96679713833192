import styled, { css } from "styled-components";
import { FlexColumn, FlexRow, H4 } from "notes";
import { useArtistModalContext } from ".";
import { useHistory } from "react-router-dom";
import { useUser } from "auth";

export const SideDrawer = ({ isMenuOpen, setIsMenuOpen }) => {
  const { setEventView, eventView } = useArtistModalContext();
  const { signOut } = useUser();
  const history = useHistory();

  const doSignOut = () => {
    signOut(false).then(() => history.push("/"));
  };

  const navArray = [
    {
      active: eventView === "upcoming",
      label: "Upcoming Shows",
      isLite: true,
      onClick: () => setEventView("upcoming"),
    },
    {
      active: eventView === "past",
      label: "Past Shows",
      isLite: true,
      onClick: () => setEventView("past"),
    },
    {
      label: "Manage Account",
      isLite: true,
      onClick: () =>
        window.open(
          `https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}/my-account`,
          "_self"
        ),
    },
    {
      active: eventView === "qr",
      label: "Artist QR Codes",
      isLite: true,
      onClick: () => setEventView("qr"),
    },
    { label: "Sign Out", onClick: () => doSignOut() },
  ];

  return (
    <>
      <Wrapper isMenuOpen={isMenuOpen}>
        {navArray.map(({ active, label, isLite, onClick }, index) => (
          <NavItem
            active={active}
            key={index}
            isLite={isLite}
            onClick={() => {
              onClick();
              setIsMenuOpen(false);
            }}
          >
            <H4>{label}</H4>
          </NavItem>
        ))}
      </Wrapper>
      <Backdrop isMenuOpen={isMenuOpen} onClick={() => setIsMenuOpen(false)} />
    </>
  );
};

const NavItem = styled(FlexRow)`
  background-color: ${(props) => (props.isLite ? "#ffffff" : "#F5F8FA")};
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  user-select: none;
  width: 100%;
  padding: 17px 24px;
  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.palette.purple.lightest};
      ${H4} {
        color: ${(props) => props.theme.palette.purple.primary};
        font-weight: 600;
      }
    `};
`;

const Wrapper = styled(FlexColumn)`
  background: #f5f8fa;
  position: fixed;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 296px;
  height: 100%;
  z-index: 9;

  box-shadow: ${(props) =>
    props.isMenuOpen ? "-8px 0 8px 0 rgba(0,0,0,0.24)" : ""};
  transform: ${(props) => (props.isMenuOpen ? "" : "translateX(-100%)")};
  will-change: transform;
  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1);
`;

const Backdrop = styled(FlexColumn)`
  background: rgba(0, 0, 0, 0.38);
  position: fixed;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 8;
  user-select: none;

  opacity: ${(props) => (props.isMenuOpen ? "0.7" : "0")};
  display: ${(props) => (props.isMenuOpen ? "block" : "none")};
`;
