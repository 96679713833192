import { PageModal, TermsContent } from "Components";
import { createContext, useState, useContext, FC, useEffect } from "react";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import {
  ModalVote,
  ModalSponsor,
  ModalPayment,
  ModalSuccess,
  ModalLeadCollection,
  ModalFreeRequest,
  ModalFreeRequestConfirm,
} from ".";
import { ModalEncore } from "./ModalEncore";

interface PaymentFormProps extends nextsong.functions.Payment {
  dedicationName?: string;
  dedicationMessage?: string;
  displayName?: string;
  occasion?: string;
  paymentMethod?: {
    id: string;
    brand: string;
    last4: string;
  };
}

interface PaymentProps {
  paymentForm?: PaymentFormProps;
  paymentSuccess?: {
    displayName: string;
    type: nextsong.functions.PaymentType;
    songId?: string;
  };
  tos?: boolean;
}

interface ModalProps {
  vote?: {
    songId?: string;
    modalTitle?: "Repeat A Song" | "Request A Song";
  };

  encoreModal?: {
    songId?: string;
    modalTitle?: string;
  };

  leadCollection?: {
    songId?: string;
    modalTitle?: string;
    description?: string;
    leadType?: string;
    modalActionText?: string;
  };

  freeRequest?: {
    songId?: string;
    modalTitle?: string;
    description?: string;
  };

  freeRequestConfirm?: {
    songId?: string;
  };

  sponsor?: {
    type: "sponsor";
    songId: string;
  };

  tos?: boolean;
}

interface FanModalContextProps {
  modalState: ModalProps;
  setModalState: React.Dispatch<React.SetStateAction<ModalProps>>;
  paymentState: PaymentProps;
  setPaymentState: React.Dispatch<React.SetStateAction<PaymentProps>>;
}

export const FanModalContext = createContext<FanModalContextProps>(null);

export const FanModalProvider: FC = ({ children }) => {
  const [modalState, setModalState] = useState<ModalProps>(null);
  const [paymentState, setPaymentState] = useState<PaymentProps>(null);
  const isModalOpen = modalState ?? paymentState;

  useEffect(() => {
    if (!!isModalOpen) {
      if (document.body.style.overflow !== "hidden")
        document.body.style.overflow = "hidden";
    } else {
      if (document.body.style.overflow !== "auto")
        document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  const value = { modalState, setModalState, paymentState, setPaymentState };
  return (
    <FanModalContext.Provider value={value}>
      <ModalVote open={!!modalState?.vote} />
      <ModalFreeRequest open={!!modalState?.freeRequest} />
      <ModalFreeRequestConfirm open={!!modalState?.freeRequestConfirm} />
      <ModalSponsor open={!!modalState?.sponsor} />
      <ModalEncore open={!!modalState?.encoreModal} />
      <ModalPayment open={!!paymentState?.paymentForm} />
      <ModalLeadCollection open={!!modalState?.leadCollection} />
      <ModalSuccess open={!!paymentState?.paymentSuccess} />
      <PageModal
        open={!!modalState?.tos}
        title="Terms of Use"
        onBack={() => setModalState(null)}
      >
        <TermsContent />
      </PageModal>
      {children}
    </FanModalContext.Provider>
  );
};

export const useFanModalContext = () => useContext(FanModalContext);
