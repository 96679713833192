import styled from "styled-components";
import { FlexColumn, FlexRow, P, Icon } from "notes";
import { useArtistEventContext, ViewProps } from ".";

interface NavProps {
  iconActive: any;
  iconInactive: any;
  label: string;
  name: ViewProps;
}

export const Navigation = () => {
  const { event, setView, view } = useArtistEventContext();

  const hasLeads =
    event?.encore?.minVoteAmount === 0 || event?.type === "checkIn";
  const isSupportDisabled = event?.customizations?.support?.disabled === true;
  const hasSupport = event?.type !== "checkIn" && !isSupportDisabled;

  let navItems: NavProps[] = [];

  if (["vote", "sponsor", "freeRequest"].includes(event?.type)) {
    navItems.push({
      iconActive: <Icon tertiary name="Music" />,
      iconInactive: <Icon form name="Music" />,
      label: event?.type === "sponsor" ? "Setlist" : "Requests",
      name: "requests",
    });
  }

  if (event?.type === "encore") {
    navItems.push({
      iconActive: <Icon form name="Record" />,
      iconInactive: <Icon form name="Record" />,
      label: "Encore",
      name: "encore",
    });
  }

  if (!!hasLeads || event?.type === "checkIn") {
    navItems.push({
      iconActive: <Icon tertiary name="Description" />,
      iconInactive: <Icon form name="Description" />,
      label: "Entries",
      name: "leads",
    });
  }

  if (hasSupport) {
    navItems.push({
      iconActive: <Icon tertiary name="Charity" />,
      iconInactive: <Icon form name="Charity" />,
      label: "Support",
      name: "support",
    });
  }

  navItems.push({
    iconActive: <Icon tertiary name="Dashboard" />,
    iconInactive: <Icon form name="Dashboard" />,
    label: "Dashboard",
    name: "dashboard",
  });

  return (
    <Wrapper>
      <Container>
        {navItems.map(({ iconActive, iconInactive, label, name }) => {
          return (
            <Option
              key={name}
              active={view === name}
              onClick={() => {
                setView(name);
              }}
            >
              {view === name ? iconActive : iconInactive}
              <P>{label}</P>
            </Option>
          );
        })}
      </Container>
    </Wrapper>
  );
};

const Container = styled(FlexRow)`
  width: 100%;
  max-width: 600px;
`;

const Wrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${(props) => props.theme.palette.gray.fill};
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 56px;
`;

const Option = styled(FlexColumn)`
  cursor: pointer;
  position: relative;
  flex: 1 1 50%;
  justify-content: center;
  align-items: center;
  svg {
    margin-bottom: 3px;
    width: 24px;
    height: 24px;
    path {
      fill: ${(props) =>
        props.active
          ? props.theme.palette.black
          : props.theme.palette.gray.lighter};
    }
  }
  ${P} {
    color: ${(props) =>
      props.active
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
  }
`;
