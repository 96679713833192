import styled from "styled-components";
import {
  H3,
  H4,
  P,
  FlexRow,
  FlexColumn,
  CalendarDay as CalendarDayBase,
} from "notes";
import { ListWrapper as ListWrapperBase } from "./Playlist";
import parse from "html-react-parser";
import {
  buildSocialLinks,
  useFanEventContext,
  useFanModalContext,
} from "./Components";
import { adjustColor, getLuminance, hexToRgb } from "Utils";
import { useEventList } from "Hooks";
import { Button } from "Components";
import { useTimeContext } from "Context";

export const getShades = (color: string) => {
  if (!color) return null;
  const pageColor = hexToRgb(color);
  const luminance = getLuminance(pageColor);
  const percent = luminance * 100;
  const backgroundOffset = percent > 50 ? -15 : percent < 1 ? 20 : -10;
  const accentOffset = percent > 50 ? -40 : 40;
  const background = adjustColor(color, backgroundOffset);
  const accent = adjustColor(color, accentOffset);
  return {
    accent,
    background,
  };
};

export const Bio = () => {
  const { event } = useFanEventContext();
  const { setModalState } = useFanModalContext();
  const { time } = useTimeContext();
  const { artistName, image, imageBanner, groupId, bio } = event;
  const {
    bioImage,
    artistBio,
    shareUrls,
    shareColor,
    actionDescription,
    actionText,
    modalTitle,
    modalDescription,
    modalActionText,
  } = bio ?? {};

  const { events } = useEventList(undefined, undefined, { groupId });

  const bioPic = bioImage || image || imageBanner;

  const socialIcons = buildSocialLinks({
    urls: shareUrls,
    color: shareColor || "default",
  });

  const allUpcomingShows = events
    ?.filter(
      ({ startsAt, _id }) =>
        startsAt.toMillis() > time.toMillis() && _id !== event?._id
    )
    ?.map(({ venue, startsAt, address }) => ({
      venue: venue,
      month: startsAt.toFormat("MMMM"),
      day: startsAt.toFormat("dd"),
      time: startsAt.toFormat("h:mm a ZZZZ"),
      location: address,
    }));

  const upcomingShows =
    allUpcomingShows?.length > 3
      ? allUpcomingShows.slice(0, 3)
      : allUpcomingShows;

  const handleLeadModal = () => {
    setModalState({
      leadCollection: {
        modalTitle: modalTitle || "Get Connected",
        modalActionText: modalActionText || "Sign Up",
        description:
          modalDescription || "Get the latest updates from the artist!",
        leadType: "subscribe",
      },
    });
  };

  return (
    <>
      <ListWrapper>
        <Image src={bioPic} alt={artistName} />
        <H3>{artistName}</H3>
        {artistBio && (
          <P style={{ marginBottom: "16px" }}>{parse(bio?.artistBio)}</P>
        )}
        {socialIcons && <SocialLinks>{socialIcons}</SocialLinks>}
        {!!upcomingShows?.length && (
          <>
            <H3>Upcoming Shows</H3>
            {upcomingShows?.map(
              ({ venue, month, day, time, location }, index) => (
                <ShowItem key={index}>
                  <CalendarDay month={month} day={day} />
                  <FlexColumn style={{ flexGrow: 1, minWidth: 0 }}>
                    <Venue>{venue}</Venue>
                    <Location>{location}</Location>
                    <Time>{time}</Time>
                  </FlexColumn>
                </ShowItem>
              )
            )}
          </>
        )}
      </ListWrapper>
      <ActionContainer>
        <P>{actionDescription || "Hear more from the artist"}</P>
        <Button style={{ minWidth: "135px" }} onClick={handleLeadModal}>
          {actionText || "Sign Up"}
        </Button>
      </ActionContainer>
    </>
  );
};

const ActionContainer = styled(FlexColumn)`
  background-color: ${(props) =>
    getShades(props.theme.branding?.event?.colors?.page)?.background};
  padding: 34px 40px 80px;
  align-items: center;
  ${P} {
    color: ${(props) => props.theme.branding.event.colors.body};
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 18px;
  }
`;

const CalendarDay = styled(CalendarDayBase)`
  background: transparent;
  flex-shrink: 0;
  margin-right: 14px;
  & > div:first-of-type {
    color: ${(props) => props.theme.branding.event.colors.body};
    background-color: ${(props) =>
      getShades(props.theme.branding?.event?.colors?.page)?.accent};
  }
  & > div:last-of-type {
    background-color: #ffffff;
  }
`;

const Venue = styled(H4)`
  color: ${(props) => props.theme.branding.event.colors.body};
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ShowItem = styled(FlexRow)`
  width: 100%;
  padding: 16px 16px 12px;
  object-fit: cover;
  overflow: hidden;
  background-color: ${(props) =>
    getShades(props.theme.branding?.event?.colors?.page)?.background};
  border-radius: 4px;
  & + & {
    margin-top: 14px;
  }
`;

const Location = styled(P)`
  color: ${(props) => props.theme.branding.event.colors.body};
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.7;
`;

const Time = styled(P)`
  color: ${(props) => props.theme.branding.event.colors.body};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
`;

const Image = styled.img`
  width: 180px;
  height: 180px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 40px;
`;

const SocialLinks = styled(FlexRow)`
  margin-bottom: 48px;
`;

const ListWrapper = styled(ListWrapperBase)`
  align-items: center;
  padding: 40px 40px 48px;
  & > ${P} {
    color: ${(props) => props.theme.branding.event.colors.body};
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
  & > ${H3} {
    margin-bottom: 16px;
    font-weight: 700;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 500px;
    margin: 0 auto;
  }
`;
