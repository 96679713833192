import styled from "styled-components";
import { FlexColumn, FlexRow, Footnote, Link as StyledLink } from "notes";
import { Link, useHistory } from "react-router-dom";
import maxLogo from "../assets/powered-by-max.png";
import logoWhiteLarge from "Images/nextsong-white-large.png";
import { useUser } from "auth";
import { useAuthContext } from "Context";

export const Footer = ({ ...props }) => {
  const {
    claims: { isAdmin },
    redirectToLogin,
  } = useUser();
  const { isArtist } = useAuthContext();
  const history = useHistory();
  return (
    <Wrapper>
      <BlockDefault>
        <Row yEnd>
          <LogoContainer>
            <Link to="/">
              <Logo src={logoWhiteLarge} alt="NextSong" />
            </Link>
            <Footnote>&copy; 2021 NextSong.Live</Footnote>
          </LogoContainer>
          <LogoContainer>
            <a target="_blank" rel="noreferrer" href="https://max.live">
              <img alt="Powered by MAX" className="powered" src={maxLogo} />
            </a>
          </LogoContainer>
        </Row>
        <LinkContainer yEnd>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Use</Link>
          <Link to="/contact">Contact Us</Link>
          <StyledLink
            onClick={() =>
              isAdmin || isArtist ? history.push("/artist") : redirectToLogin()
            }
          >
            Login
          </StyledLink>
        </LinkContainer>
      </BlockDefault>
      <LanguageContainer />
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn).attrs({ as: "footer" })`
  background-color: #000000;
  display: flex;
  align-items: center;
`;

const BlockDefault = styled(FlexColumn).attrs({ as: "section" })`
  background-color: #000000;
  padding: 7.34vw 40px 32px;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  .logo {
    width: 128px;
  }
  .powered {
    width: 111px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;

    .logo {
      width: 100px;
    }
    .powered {
      width: 87px;
    }
    padding: 18vw 24px 32px;
    padding-bottom: 0;
  }
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: -4px;
  object-fit: contain;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 109px;
  }
`;

const LogoContainer = styled(FlexColumn)`
  align-items: flex-start;
`;

const Row = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
  ${Footnote} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-family: ${(props) => props.theme.branding.bodyFont};
    font-style: normal;
    margin-top: 7px;
    margin-bottom: 0;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    height: auto;
    margin-bottom: 32px;
  }
`;

const LinkContainer = styled(FlexRow)`
  align-content: flex-end;
  justify-content: flex-end;
  margin-top: 24px;
  a {
    font-family: ${(props) => props.theme.branding.bodyFont};
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    text-decoration: none;
    &:hover,
    &:focus {
      color: #fff;
    }
  }
  a + a {
    margin-left: 24px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: flex-start;
    flex-wrap: wrap;
    a {
      margin-bottom: 24px;
      width: 50%;
      color: #ffffff;
    }
    a + a {
      margin-left: 0;
    }
  }
`;

const LanguageContainer = styled(FlexRow)`
  background-color: ${(props) => props.theme.palette.black};
  height: 40px;
  width: 100%;
`;
