import { useAppContext } from "Context";
import React, {
  createContext,
  useContext,
  useState,
  FC,
  useEffect,
} from "react";
import { ModalQRView } from ".";
import { ModalAddShow } from "./ModalAddShow";
import { ModalEndEvent } from "./ModalEndEvent";
import { Notification } from "./Notification";
import { ModalVenueAddress } from "./ModalVenueAddress";
import { SideDrawer } from "./SideDrawer";
import { Dialog } from "./Dialog";

interface ShowDetailProps {
  venue: string;
  date: string;
  time: string;
  type: string;
}

interface VenueAddressProps {
  venue: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: number;
}

export interface NotificationProps {
  title?: string;
  message?: string;
  type?: "success" | "error" | "delete";
  timeout?: number;
}

interface DialogProps extends Omit<NotificationProps, "timeout"> {
  actions?: {
    cancel?: string;
    submit?: string;
  };
  onConfirm?: () => void;
}

interface AddSongsProps {
  songId?: string;
  title?: string;
  artist?: string;
}

interface ArtistModalProps {
  endEvent?: string;
  venueAddress?: string | boolean;
  addShow?: boolean;
  qrView?: string;
  editEvent?: boolean;
  addSong?: AddSongsProps;
}

interface ArtistModalContextProps {
  modal: ArtistModalProps;
  setModal: React.Dispatch<React.SetStateAction<ArtistModalProps>>;
  eventView: "past" | "upcoming" | "qr";
  setEventView: React.Dispatch<React.SetStateAction<string>>;
  venueAddress: VenueAddressProps;
  setVenueAddress: React.Dispatch<React.SetStateAction<VenueAddressProps>>;
  showDetail: ShowDetailProps;
  setShowDetail: React.Dispatch<React.SetStateAction<ShowDetailProps>>;
  closeModal: (val: void) => void;
  groupIdView?: string;
  setGroupIdView: React.Dispatch<React.SetStateAction<string>>;
  dialog: DialogProps;
  setDialog: React.Dispatch<React.SetStateAction<DialogProps>>;
  notification: NotificationProps;
  setNotification: React.Dispatch<React.SetStateAction<NotificationProps>>;
}

const ArtistModalContext = createContext<ArtistModalContextProps>(
  {} as ArtistModalContextProps
);

const ArtistModalProvider: FC = ({ children }) => {
  const { isSideDrawerOpen, setIsSideDrawerOpen } = useAppContext();
  const [modal, setModal] = useState<ArtistModalProps>(null);
  const [showDetail, setShowDetail] = useState<ShowDetailProps>(null);
  const [venueAddress, setVenueAddress] = useState<VenueAddressProps>(null);
  const [eventView, setContextEventView] =
    useState<ArtistModalContextProps["eventView"]>("upcoming");
  const [groupIdView, setGroupIdView] = useState<string>(null);
  const [dialog, setDialog] = useState<DialogProps>(null);
  const [notification, setNotification] = useState<NotificationProps>(null);

  const setEventView = (view: ArtistModalContextProps["eventView"]) => {
    if (!!groupIdView && view !== eventView) {
      setGroupIdView(null);
    }
    setContextEventView(view);
  };

  const closeModal = () => setModal(null);

  useEffect(() => {
    if (!!modal) {
      if (document.body.style.overflow !== "hidden")
        document.body.style.overflow = "hidden";
    } else {
      if (document.body.style.overflow !== "auto")
        document.body.style.overflow = "auto";
    }
  }, [modal]);

  const value: ArtistModalContextProps = {
    modal,
    setModal,
    closeModal,
    eventView,
    setEventView,
    venueAddress,
    setVenueAddress,
    showDetail,
    setShowDetail,
    groupIdView,
    setGroupIdView,
    dialog,
    setDialog,
    notification,
    setNotification,
  };

  return (
    <ArtistModalContext.Provider value={value}>
      <ModalEndEvent open={!!modal?.endEvent} />
      <ModalVenueAddress open={!!modal?.venueAddress} />
      <ModalQRView open={!!modal?.qrView} />
      <ModalAddShow open={!!modal?.addShow} />
      <Notification />
      <Dialog />
      <SideDrawer
        isMenuOpen={isSideDrawerOpen}
        setIsMenuOpen={setIsSideDrawerOpen}
      />
      {children}
    </ArtistModalContext.Provider>
  );
};

export { ArtistModalProvider, ArtistModalContext };

export const useArtistModalContext = () => useContext(ArtistModalContext);
