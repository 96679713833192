import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  updateDoc,
  connectFirestoreEmulator,
  addDoc,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import type { DocumentReference } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_USE_EMULATOR
) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const updateFirestoreDoc = async (
  path: string,
  data?: Record<string, any>,
  options?: { deleteDoc?: boolean }
): Promise<void> => {
  const ref = doc(firestore, path);
  if (options?.deleteDoc) {
    return deleteDoc(ref);
  }
  if (!!data) {
    return updateDoc(ref, data);
  }
};

const addFirestoreDoc = async (
  path: string,
  data: Record<string, any>
): Promise<DocumentReference> => {
  return addDoc(collection(firestore, path), data);
};

auth.useDeviceLanguage();

export default app;
export { firestore, auth, functions, updateFirestoreDoc, addFirestoreDoc };
