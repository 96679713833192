import { useContext } from "react";
import { AnalyticsContext } from "Context";

// ----------------------------------------------------------------------

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) throw new Error("Analytics context not found");

  return context;
};

export default useAnalytics;
