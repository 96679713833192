import styled from "styled-components";
import { FlexColumn, Modal, H4, P, ModalContainer } from "notes";
import { useArtistModalContext } from ".";
import { IllCheck, IllDelete } from "Images";
import { useEffect } from "react";

export const Notification = () => {
  const { notification, setNotification } = useArtistModalContext();
  const isOpen = notification;
  return (
    <StyledModal
      size="small"
      open={isOpen}
      setOpen={() => setNotification(null)}
    >
      <NotificationComponent />
    </StyledModal>
  );
};

export const NotificationComponent = () => {
  const { setNotification, notification } = useArtistModalContext();
  const { title, message, type, timeout = 0 } = notification;

  const handleClose = () => {
    setNotification(null);
  };

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        handleClose();
      }, timeout);
    }
  }, [timeout]);

  return (
    <Wrapper>
      {type === "delete" && <IllDelete />}
      {type === "success" && <IllCheck />}
      <H4>{title}</H4>
      <P>{message}</P>
    </Wrapper>
  );
};

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    border-radius: 8px;
    padding: 24px;
    width: 300px;
    min-height: 0;
    & > svg {
      width: 18px;
      height: 18px;
      top: 16px;
      right: 16px;
    }
  }
`;

const Wrapper = styled(FlexColumn)`
  align-items: center;
  ${H4} {
    color: ${(props) => props.theme.palette.black};
    font-weight: 700;
    text-align: center;
    margin-top: 12px;
  }
`;
