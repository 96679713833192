import styled from "styled-components";
import { Button as ButtonBase, Link as LinkBase } from "notes";

export const Button = styled(ButtonBase)`
  background-color: ${(props) =>
    props.theme.branding.event.colors.action ??
    props.theme.branding.colors.action};
  color: ${(props) => props.theme.branding.colors.actionLabel};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${(props) =>
      props.theme.branding.event.colors.action ??
      props.theme.branding.colors.action};
    color: ${(props) => props.theme.branding.colors.actionLabel};
  }
`;

export const ButtonSecondary = styled(ButtonBase).attrs({ type: "tertiary" })`
  background-color: ${(props) => props.theme.branding.colors.actionSecondary};
  color: ${(props) => props.theme.branding.colors.actionSecondaryLabel};
  border-color: ${(props) => props.theme.branding.colors.actionSecondaryBorder};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${(props) =>
      props.theme.branding.colors?.actionSecondary};
    color: ${(props) => props.theme.branding.colors.actionSecondaryLabel};
  }
`;

export const ButtonLink = styled(ButtonBase).attrs({ type: "link" })`
  color: ${(props) =>
    props.theme.branding.event.colors.action ??
    props.theme.branding.colors.action};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${(props) =>
      props.theme.branding.event.colors.action ??
      props.theme.branding.colors.action};
  }
`;

export const Link = styled(LinkBase)`
  color: ${(props) =>
    props.theme.branding.event.colors.action ??
    props.theme.branding.colors.action};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${(props) =>
      props.theme.branding.event.colors.action ??
      props.theme.branding.colors.action};
  }
`;
