import { createContext, useContext, useState, FC, useEffect } from "react";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import {
  DocumentModel,
  useCollectionModel,
  useDocumentModel,
} from "Hooks/models";
import { firestore } from "firebase-internal";
import { collection, query, doc, Timestamp } from "firebase/firestore";
import { updateRequests, UpdatedEventSongModel } from "./updateRequests";
import { useHistory } from "react-router-dom";
import { BrandOverrides, useBrandingContext } from "Context";
import { RequestModel } from "Routes/Artist/Components";
import { ThemeProvider, useTheme } from "styled-components";
import { mergeTypography } from "theme";
import { Helmet } from "react-helmet-async";
import { useUser } from "auth";
export type EventModel = DocumentModel<nextsong.firestore.Event>;
interface EventOverrides {
  bannerGradientOffset?: number;
  bannerHeight?: number;
  disableImageBlur?: boolean;
  googleFontUrl?: string;
  headerFont?: string;
  bodyFont?: string;
  support?: {
    disabled: boolean;
    buttonText?: string;
  };
  catalog?: {
    tab?: string;
    title?: string;
    description?: string;
    actionText?: string;
    actionRepeatText?: string;
  };
  vote?: {
    tab?: string;
    title?: string;
    description?: string;
    actionText?: string;
  };
  sponsor?: {
    tab?: string;
    title?: string;
    description?: string;
    actionText?: string;
    actionCompleteText: string;
    actionUnavailableText: string;
  };
  encore?: {
    tab?: string;
    title?: string;
    description?: string;
    caption?: string;
    actionText?: string;
    actionRepeatText: string;
    actionCompleteText: string;
  };
  colors?: {
    header?: string;
    headerActions?: string;
    page?: string;
    artist?: string;
    venue?: string;
    date?: string;
    divider?: string;
    title?: string;
    description?: string;
    caption?: string;
    action?: string;
    actionLabel?: string;
    listTitle?: string;
    listSubtitle?: string;
    listDivider?: string;
    progressBar?: string;
    progressBarBackground?: string;
    tabs?: {
      activeBackground?: string;
      activeText?: string;
      inactiveBackground?: string;
      inactiveText?: string;
    };
  };
}
interface FanEventContextProps {
  event: EventModel;
  eventLoading: boolean;
  playlist: UpdatedEventSongModel[];
  view: string;
  setView: (val: string) => void;
  myRequests: RequestModel[];
  topVoteRequestAccumAmount: number;
  voteRequests: UpdatedEventSongModel[];
  encorePaymentAllowed: boolean;
}

export const FanEventContext = createContext<FanEventContextProps>(
  {} as FanEventContextProps
);

export const FanEventProvider: FC<{ eventId: string }> = ({
  eventId,
  children,
}) => {
  const { user } = useUser();
  const { googleFontUrl } = useBrandingContext();
  const [eventSnapshot, eventLoading] = useDocumentModel(
    nextsong.firestore.Event,
    doc(firestore, `nextsong_events/${eventId}`)
  );
  const history = useHistory();
  const MAXTheme = useTheme();

  const event = {
    ...eventSnapshot,
    startsAt: eventSnapshot?.startsAt?.toLocal(),
  };

  if (
    (!eventLoading && eventSnapshot === undefined) ||
    eventSnapshot?.status === "deleted"
  ) {
    history.push("/");
  }

  if (!!event?.endedAt) {
    const extendedEndTime = eventSnapshot?.endedAt
      ?.plus({ hours: 8 })
      .toMillis();
    const nowTime = Timestamp.now().toMillis();
    if (nowTime > extendedEndTime) {
      history.push("/");
    }
  }

  const [playlistSnapshot] = useCollectionModel(
    nextsong.firestore.EventSong,
    query(collection(firestore, `nextsong_events/${eventId}/playlist`))
  );

  const [requestsSnapshot] = useCollectionModel(
    nextsong.firestore.Request,
    query(collection(firestore, `nextsong_events/${eventId}/requests`))
  );

  const playlist: UpdatedEventSongModel[] = updateRequests(
    playlistSnapshot,
    requestsSnapshot,
    true
  );

  const voteRequests = playlist?.filter(
    ({ accumAmount, playedAt }) => !playedAt?.length && accumAmount > 0
  );

  const topVoteRequestAccumAmount = Math.max.apply(
    Math,
    voteRequests?.map((item) => item.accumAmount)
  );

  const myRequests = requestsSnapshot?.filter(
    (request) => request.uid === user.uid
  );

  const encorePaymentAllowed = event?.encore?.minVoteAmount > 0;

  const defaultView =
    event?.type === "encore"
      ? "encore"
      : event?.type === "sponsor"
      ? "sponsor"
      : event?.type === "checkIn"
      ? "checkIn"
      : event?.type === "freeRequest"
      ? "freeRequest"
      : "playlist";

  useEffect(() => {
    setView(defaultView);
  }, [defaultView]);

  const [view, setView] = useState(defaultView);

  const value = {
    event,
    eventLoading,
    playlist,
    view,
    setView,
    myRequests,
    voteRequests,
    topVoteRequestAccumAmount,
    encorePaymentAllowed,
  };

  const brandStyles: BrandOverrides = MAXTheme["branding"];
  const customizations: nextsong.firestore.Event["customizations"] =
    event?.customizations ?? {};

  const fonts = {
    headerFont: customizations?.headerFont ?? brandStyles.headerFont,
    bodyFont: customizations?.bodyFont ?? brandStyles.bodyFont,
  };

  const mergedCustomizations = {
    ...brandStyles,
    ...fonts,
    event: {
      ...brandStyles.event,
      ...customizations,
      colors: {
        ...brandStyles.event.colors,
        ...customizations?.colors,
        tabs: {
          ...brandStyles.event.colors.tabs,
          ...customizations?.colors?.tabs,
        },
      },
    },
  };
  const updatedTheme = {
    ...MAXTheme,
    branding: mergedCustomizations,
    typography: mergeTypography(
      {
        placeholderFont: brandStyles.colors.input.placeholderFont,
        inputFont: brandStyles.colors.input.font,
        bodyFont: fonts.bodyFont,
        headerFont: fonts.headerFont,
      },
      MAXTheme
    ),
  };

  const googleLink = event?.customizations?.googleFontUrl ?? googleFontUrl;

  return (
    <FanEventContext.Provider value={value}>
      <ThemeProvider theme={updatedTheme}>
        <Helmet>
          <link href={googleLink} rel="stylesheet" />
        </Helmet>
        {children}
      </ThemeProvider>
    </FanEventContext.Provider>
  );
};

export const useFanEventContext = () => useContext(FanEventContext);
