import styled from "styled-components";
import Joi from "@hapi/joi";
import {
  H4,
  P,
  FlexColumn,
  Label,
  Icon,
  FieldMessage,
  Input,
  TextArea,
} from "notes";
import { NavHeader, Footer, Button } from "Components";
import { useEffect, useState } from "react";
import { addFirestoreDoc } from "firebase-internal";
import { validate } from "Utils";
import { useHistory } from "react-router-dom";
import { useUser } from "auth";

const formSchema = Joi.object({
  name: Joi.string().min(1).required(),
  email: Joi.string().email({ tlds: false }).required(),
  message: Joi.string().min(3).required(),
});

export const Contact = (props) => {
  const { user } = useUser();
  const [form, setForm] = useState({
    type: "contact",
    name: "",
    email: "",
    message: "",
    uid: user?.uid ?? "",
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleChange = (value, name) => {
    setForm({ ...form, [name]: value });
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async (form) => {
    const isError = validate(form, formSchema);
    setLoading(true);
    if (!isError) {
      setLoading(true);
      const docRef = await addFirestoreDoc("nextsong_feedback", form);
      if (docRef.id) {
        setSuccess(true);
      }
    } else {
      setErrors(isError);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavHeader
        secondary
        onBack={() => {
          if (!!props?.location?.state?.hasHistory) {
            history.goBack();
          } else {
            history.push("/");
          }
        }}
      />
      <Wrapper>
        <H4>Contact Us</H4>
        {success ? (
          <FlexColumn style={{ marginTop: "40px" }}>
            <P>
              Thanks - We'll review your question and get back to you as soon as
              possible.
            </P>
          </FlexColumn>
        ) : (
          <FlexColumn>
            <P>
              We're here to help. Fill out the form and we will get back to you
              shortly.
            </P>
            <Form>
              <FlexColumn>
                <Label>Name</Label>
                <Input
                  onChange={(value) => handleChange(value, "name")}
                  placeholder="First and last name..."
                  value={form.name}
                  error={!!errors["name"]}
                />
                <FieldMessage
                  active={!!errors["name"]}
                  content="This is a required field and cannot be blank."
                />

                <Label>Email address</Label>
                <Input
                  leftIcon={<Icon form name="Email" />}
                  onChange={(value) => handleChange(value, "email")}
                  placeholder="john@email.com"
                  value={form.email}
                  error={!!errors["email"]}
                />
                <FieldMessage
                  active={!!errors["email"]}
                  content="This is a required field and cannot be blank."
                />
              </FlexColumn>
              <Label>Message</Label>
              <TextArea
                onChange={(e) => handleChange(e.target.value, "message")}
                placeholder="Comments..."
                value={form.message}
                error={!!errors["message"]}
                style={{ minHeight: "102px" }}
              />
              <FieldMessage
                active={!!errors["message"]}
                content="Please provide a short description of your reason for contacting us."
              />

              <Button
                loading={loading}
                disabled={loading}
                onClick={() => handleSubmit(form)}
                style={{ marginTop: "40px" }}
              >
                Submit
              </Button>
            </Form>
          </FlexColumn>
        )}
      </Wrapper>
      <Footer />
    </>
  );
};

const StyledLabel = styled(Label)`
  color: ${(props) =>
    props.light ? props.theme.palette.gray.lighter : props.theme.palette.black};
  margin-bottom: 4px;
`;

const Wrapper = styled(FlexColumn)`
  margin: 0 auto;
  padding: 40px 24px;
  width: 100%;
  flex-grow: 1;
  ${H4} {
    font-family: ${(props) => props.theme.branding.headerFont};
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
  ${P} {
    font-size: 15px;
  }
  ${P} + ${StyledLabel} {
    margin-top: 24px;
  }
`;

const Form = styled(FlexColumn)`
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
`;
