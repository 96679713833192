import styled from "styled-components";
import {
  FlexColumn,
  Modal,
  H4,
  Button,
  ModalContainer,
  Input,
  Label,
  Checkbox,
} from "notes";
import { addFirestoreDoc, updateFirestoreDoc } from "firebase-internal";
import { NotificationProps, useArtistModalContext } from "../Components";
import { useState } from "react";
import { useArtistEventContext } from "../Components/ArtistEventContext";
import Joi from "@hapi/joi";
import { validate } from "Utils";

export const ModalAddEditSong = () => {
  const { modal } = useArtistModalContext();
  const isOpen = !!modal?.addSong;
  return (
    <StyledModal size="small" open={isOpen}>
      <AddEditSong />
    </StyledModal>
  );
};

interface FormProps {
  title?: string;
  artist?: string;
  encore?: boolean;
}

interface ErrorProps {
  [key: string]: string;
}

const formSchema = Joi.object({
  title: Joi.string().min(1).required(),
  artist: Joi.string().required(),
  encore: Joi.boolean(),
});

export const AddEditSong = () => {
  const { setModal, modal, setNotification } = useArtistModalContext();
  const { event } = useArtistEventContext();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorProps>({});
  const editSongId = modal?.addSong?.songId;

  const defaultValue = !!editSongId
    ? modal?.addSong
    : {
        title: "",
        artist: "",
      };

  const [form, setForm] = useState<FormProps>(defaultValue);

  const closeModal = (notification?: NotificationProps) => {
    let uModal = { ...modal };
    delete uModal.addSong;
    setModal(uModal);
    if (notification) {
      setNotification(notification);
    }
  };

  const handleSubmit = async () => {
    const formErrors = validate(form, formSchema);
    if (!formErrors) {
      if (!!editSongId) {
        let payload: FormProps = { title: form.title, artist: form.artist };
        if (form.encore) payload.encore = true;
        await updateFirestoreDoc(
          `nextsong_events/${event._id}/playlist/${editSongId}`,
          payload
        );
      } else {
        await addFirestoreDoc(`nextsong_events/${event._id}/playlist`, form);
      }
    } else {
      setErrors(formErrors);
      return;
    }
    closeModal({
      type: "success",
      title: "Success!",
      message: `Song was ${editSongId ? "updated" : "added"}.`,
      timeout: 3500,
    });
  };

  const handleChange = (name, value) => {
    setIsDirty(true);
    setForm({ ...form, [name]: value });
  };

  return (
    <Wrapper>
      <H4>{!!editSongId ? "Edit Song" : "Add a New or Unlisted Song"}</H4>
      <FlexColumn>
        <Label>Song Title</Label>
        <Input
          value={form.title}
          onChange={(v) => {
            if (errors.title) delete errors.title;
            setErrors(errors);
            handleChange("title", v);
          }}
          placeholder="title..."
          error={errors?.title}
        />
        <Label>Artist Name</Label>
        <Input
          value={form.artist}
          onChange={(v) => {
            if (errors.artist) delete errors.artist;
            setErrors(errors);
            handleChange("artist", v);
          }}
          placeholder="artist..."
          style={{ marginBottom: "8px" }}
          error={errors?.artist}
        />
        {event?.type === "encore" && (
          <Checkbox
            checked={form?.encore}
            onChange={(v) => handleChange("encore", v)}
          >
            Include in encore list
          </Checkbox>
        )}
      </FlexColumn>
      <Actions xEnd>
        <Button disabled={!isDirty} onClick={handleSubmit}>
          {!!editSongId ? "Save Changes" : "Add to Catalog"}
        </Button>
        <Button type="tertiary" onClick={() => closeModal()}>
          Cancel
        </Button>
      </Actions>
    </Wrapper>
  );
};

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    border-radius: 8px;
    padding: 24px;
    width: 300px;
    min-height: 0;
    & > svg {
      display: none;
    }
  }
`;

const Actions = styled(FlexColumn)`
  padding: 24px 0 0 0;
  button {
    width: 100%;
    & + button {
      margin-top: 8px;
    }
  }
`;

const Wrapper = styled(FlexColumn)`
  ${H4} {
    color: ${(props) => props.theme.palette.black};
    font-weight: 700;
  }
  ${Label} {
    margin-top: 16px;
  }
`;
