import styled from "styled-components";
import { FlexColumn, FlexRow, Modal, ModalContainer, P } from "notes";
import { LogoGradient } from "Images";
import { Link } from "Components";
import { useBrandingContext } from "Context";

interface DrawerProps {
  open: boolean;
  onClose: (val: void) => void;
  children: any;
  title?: string;
  hideAction?: boolean;
  showSearch?: boolean;
  searchResults?: any;
}

export const Drawer: React.FC<DrawerProps> = ({
  open = false,
  onClose,
  children,
  title = "",
  hideAction = false,
}) => {
  const { logoAlt } = useBrandingContext();
  return (
    <Wrapper size="small" open={open} setOpen={() => {}}>
      <Chrome yCenter>
        <ChromeContent>
          {title ? (
            <P>{title}</P>
          ) : logoAlt ? (
            <img src={logoAlt} alt="" />
          ) : (
            <LogoGradient />
          )}
          {!hideAction && <Link onClick={() => onClose()}>Cancel</Link>}
        </ChromeContent>
      </Chrome>
      <Content>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled(Modal)`
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  ${ModalContainer} {
    align-items: center;
    overflow-y: scroll;
    align-self: initial;
    padding: 0;
    margin-top: 0;
    max-height: 100%;
    min-height: initial;
    max-width: 600px;
    transition: height 0.2s cubic-bezier(0, 0, 0.3, 1);
    img {
      object-fit: contain;
      width: 121px;
      margin-bottom: -4px;
    }
    & > svg {
      display: none;
    }
    @media only screen and ${(props) => props.theme.media.medium} {
      border-radius: 6px 6px 0 0;
    }
  }
`;

const Content = styled(FlexColumn)`
  width: 100%;
  max-width: 600px;
`;

const ChromeContent = styled(Content)`
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
`;

const Chrome = styled(FlexRow)`
  background: #ffffff;
  border-bottom: 2px solid ${(props) => props.theme.palette.gray.lightest};
  flex: 0 0 48px;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 500;
  ${P} {
    font-weight: 700;
  }
`;
