import styled from "styled-components";
import { FlexColumn, Modal, H4, P, ModalContainer, Button } from "notes";
import { useArtistModalContext } from ".";
import { IllCheck, IllDelete } from "Images";

export const Dialog = () => {
  const { dialog, setDialog } = useArtistModalContext();
  const isOpen = dialog;
  return (
    <StyledModal size="small" open={isOpen} setOpen={() => setDialog(null)}>
      <DialogComponent />
    </StyledModal>
  );
};

export const DialogComponent = () => {
  const { setDialog, dialog } = useArtistModalContext();
  const { title, message, type, actions, onConfirm } = dialog;

  const handleClose = () => {
    setDialog(null);
  };

  const SubmitButton = ["delete", "error"].includes(type) ? EndButton : Button;

  return (
    <Wrapper>
      {type === "delete" && <IllDelete />}
      {type === "success" && <IllCheck />}
      <H4>{title}</H4>
      <P>{message}</P>
      {actions && (
        <Actions xEnd>
          <SubmitButton onClick={() => onConfirm()}>
            {actions.submit || "Confirm"}
          </SubmitButton>
          <Button type="tertiary" onClick={handleClose}>
            {actions.cancel || "Cancel"}
          </Button>
        </Actions>
      )}
    </Wrapper>
  );
};

const Actions = styled(FlexColumn)`
  padding: 24px 0 0 0;
  button {
    width: 100%;
    & + button {
      margin-top: 8px;
    }
  }
`;

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    border-radius: 8px;
    padding: 24px;
    width: 300px;
    min-height: 0;
    & > svg {
      width: 18px;
      height: 18px;
      top: 16px;
      right: 16px;
    }
  }
`;

const Wrapper = styled(FlexColumn)`
  align-items: center;
  ${H4} {
    color: ${(props) => props.theme.palette.black};
    font-weight: 700;
    text-align: center;
    margin-top: 12px;
  }
`;

const EndButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.red.primary};
  margin-bottom: 8px;
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${(props) => props.theme.palette.red.darker};
  }
`;
