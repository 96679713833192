import styled from "styled-components";
import parse from "html-react-parser";
import { H3, Subheader, FlexRow, H4, Small } from "notes";
import { useFanEventContext, useFanModalContext } from "./Components";
import { ListWrapper as ListWrapperBase } from "./Playlist";
import { Button } from "Components";
import { useAnalytics } from "Hooks";

export const CheckIn = () => {
  const { logClick } = useAnalytics();
  const { event, myRequests } = useFanEventContext();
  const { setModalState } = useFanModalContext();
  const checkIn = event?.checkIn;
  return (
    <ListWrapper>
      <H3>{checkIn?.title ?? "Check In"}</H3>
      <Subheader>
        {checkIn?.description ??
          "Check in and let the artist know you're here!"}
      </Subheader>

      <Content xCenter>
        {!myRequests?.length ? (
          checkIn?.preCheckInHtml ? (
            parse(checkIn?.preCheckInHtml)
          ) : (
            <H4>Check in now</H4>
          )
        ) : checkIn?.postCheckInHtml ? (
          parse(checkIn?.postCheckInHtml)
        ) : (
          <H4>Thanks for checking in!</H4>
        )}
      </Content>

      {!myRequests?.length && (
        <FlexRow style={{ marginTop: "24px" }} xCenter>
          <Button
            onClick={() => {
              logClick({
                action: "open_checkIn_modal",
                eventId: event._id,
              });
              setModalState({
                leadCollection: {
                  modalTitle: checkIn?.modalTitle ?? checkIn?.title,
                  description:
                    checkIn?.modalDescription ?? checkIn?.description,
                },
              });
            }}
            style={{ maxWidth: "392px", width: "100%" }}
          >
            {checkIn?.actionText ?? "Join Now"}
          </Button>
        </FlexRow>
      )}
    </ListWrapper>
  );
};

const Content = styled(FlexRow)`
  ${H4} {
    color: ${(props) => props.theme.branding.event.colors.title};
    font-family: ${(props) => props.theme.branding.headerFont};
  }
  ${Small} {
    color: ${(props) => props.theme.branding.event.colors.description};
  }
  color: ${(props) => props.theme.branding.event.colors.body};
`;

const ListWrapper = styled(ListWrapperBase)`
  font-family: ${(props) => props.theme.branding.bodyFont};
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
  padding: 24px 16px 80px 16px;
  & > ${FlexRow} {
    width: 100%;
  }
  ${Subheader} {
    margin-bottom: 24px;
  }
  ${H4}, ${Small} {
    width: 100%;
    text-align: center;
  }
  ${Small} {
    margin-bottom: 16px;
  }
  a.styled {
    background-color: ${(props) =>
      props.theme.branding.event.colors.action ??
      props.theme.branding.colors.action};
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    padding: 4px 6px;
    border-radius: 4px;
    border: none;
    color: ${(props) =>
      props.theme.branding.event.colors.actionLabel ??
      props.theme.branding.colors.actionLabel};
    &:hover {
      color: ${(props) =>
        props.theme.branding.event.colors.actionLabel ??
        props.theme.branding.colors.actionLabel};
      text-decoration: none;
    }
  }
`;
