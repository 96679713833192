import styled from "styled-components";
import {
  useFanEventContext,
  useFanModalContext,
  ActionButton,
} from "./Components";
import {
  FlexColumn,
  FlexRow,
  H3,
  H4,
  P,
  Subheader,
  Footnote,
  Link,
  Icon,
} from "notes";
import { ListWrapper } from "./Playlist";
import { useAnalytics } from "Hooks";

export const Requests = () => {
  const { playlist, setView, topVoteRequestAccumAmount, event } =
    useFanEventContext();
  const songs = playlist?.filter(
    ({ accumAmount, playedAt }) => !playedAt?.length && accumAmount > 0
  );
  const { setModalState } = useFanModalContext();
  const { logClick } = useAnalytics();

  const handleClick = (songId) => {
    logClick({
      action: "submitting_song_request",
      eventId: event._id,
    });
    setModalState({
      vote: {
        songId,
      },
    });
  };

  const overrides = event?.customizations?.vote;

  return (
    <ListWrapper>
      {!!songs?.length && (
        <FlexColumn>
          <H3>{overrides?.title ?? "Top Requests"}</H3>
          <Subheader>
            {overrides?.description ??
              "Here are the songs fans want to hear next! Vote for your favorites!"}
          </Subheader>
        </FlexColumn>
      )}
      <List>
        {songs?.length ? (
          songs
            ?.sort((a, b) => b.accumAmount - a.accumAmount)
            ?.map(({ _id: songId, title, artist, accumAmount }, index) => {
              const calcPercent =
                (accumAmount / topVoteRequestAccumAmount) * 100;
              return (
                <RequestWrapper key={index}>
                  <Information yStart>
                    <FlexColumn flex="1 1 100%" xStart>
                      <FlexRow>
                        <P>
                          {index < 9 && "0"}
                          {index + 1}.
                        </P>
                        <P>{title}</P>
                      </FlexRow>
                      <Footnote>{artist}</Footnote>
                    </FlexColumn>
                    <ActionButton onClick={() => handleClick(songId)}>
                      {overrides?.actionText ?? "Vote"}
                    </ActionButton>
                  </Information>
                  <PercentageWrapper>
                    <PercentageBar percent={calcPercent} />
                  </PercentageWrapper>
                </RequestWrapper>
              );
            })
        ) : (
          <NoRequests xCenter>
            <H3>No Requests</H3>
            <H4>
              Checkout our{" "}
              <Link onClick={() => setView("playlist")}>
                {event.customizations?.catalog?.tab ?? "catalog"}
              </Link>{" "}
              to {overrides?.actionText ?? "vote"} for the next song!
            </H4>
            <FlexColumn xCenter onClick={() => setView("playlist")}>
              <Icon form name="Record" />
              <Footnote>
                {event.customizations?.catalog?.tab ?? "Catalog"}
              </Footnote>
            </FlexColumn>
          </NoRequests>
        )}
      </List>
    </ListWrapper>
  );
};

const NoRequests = styled(FlexColumn)`
  margin-top: 40px;
  margin-right: 16px;
  ${H3} {
    color: ${(props) => props.theme.branding.event.colors.title};
    margin-bottom: 8px;
  }
  ${H4} {
    color: ${(props) => props.theme.branding.event.colors.description};
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 24px;
    max-width: 244px;
    text-align: center;
  }
  ${Link} {
    color: ${(props) => props.theme.branding.event.colors.description};
    text-decoration: underline;
  }
  svg {
    color: ${(props) => props.theme.branding.event.colors.title};
    width: 64px;
    height: 64px;
    margin-bottom: 4px;
  }
  ${Footnote} {
    color: ${(props) => props.theme.branding.event.colors.title};
    font-size: 22px;
    font-style: normal;
    line-height: 25px;
  }
`;

const List = styled(FlexColumn)`
  width: 100%;
`;

const RequestWrapper = styled(FlexColumn)`
  margin-top: 16px;
  padding-right: 16px;
  &:not(:last-child) {
    border-bottom: 1px solid
      ${(props) => props.theme.branding.event.colors.listDivider};
  }
  ${P} {
    color: ${(props) => props.theme.branding.event.colors.listTitle};
    font-size: 15px;
    font-weight: 600;
    &:first-of-type {
      width: 23px;
      font-weight: 300;
    }
  }
  ${P} + ${P} {
    flex-grow: 1;
    margin-left: 4px;
  }
  ${Footnote} {
    color: ${(props) => props.theme.branding.event.colors.listSubtitle};
    margin-left: 28px;
    font-style: normal;
    font-weight: 600;
  }
`;

const Information = styled(FlexRow)`
  margin-bottom: 12px;
`;

export const PercentageWrapper = styled(FlexRow)`
  flex-grow: 1;
  margin-bottom: 17px;
  margin-right: 24px;
  height: 8px;
  border-radius: 4px;
  background: ${(props) =>
    props.theme.branding.event.colors.progressBarBackground};
  width: 100%;
`;

interface Props {
  percent: number;
}
export const PercentageBar = styled.div<Props>`
  height: 8px;
  border-radius: 4px;
  background: ${(props) => props.theme.branding.event.colors.progressBar};
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);
  width: ${(props) => props.percent}%;
`;
