import { ReactText, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnalyticsContext, useTimeContext } from "Context";
import {
  FlexColumn,
  H3,
  H4,
  Subheader,
  Icon,
  Footnote,
  FlexRow,
  P,
  Input,
} from "notes";
import { Duration, DateTime } from "luxon";
import { textCompare } from "Utils";
import {
  useFanEventContext,
  useFanModalContext,
  ActionButton,
  ShowSupport,
} from "./Components";
import { toast, collapseToast } from "react-toastify";
import { Button, Link } from "Components";
import { useCustomerDetails } from "Hooks";

export const getTimeSince = (
  fbDate: DateTime,
  time: DateTime
): { minutes?: number; hours?: number } => {
  if (!fbDate || !time) {
    return {};
  }
  const requestedAt = fbDate;
  const timeSince = Duration?.fromMillis(
    time?.toMillis() - requestedAt?.toMillis()
  );
  return {
    minutes: parseInt(timeSince?.toFormat("m")),
    hours: parseInt(timeSince?.toFormat("h")),
  };
};

//https://usehooks.com/useLocalStorage/
function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}

export const FreeRequest = () => {
  const { event, playlist: songs, myRequests } = useFanEventContext();
  const [notifications, setNotifications] = useLocalStorage<{}>(
    "notifications",
    {}
  );
  const toastId = useRef<undefined | ReactText>();
  const { setModalState } = useFanModalContext();
  const [search, setSearch] = useState("");
  const { time } = useTimeContext();
  const { logClick } = useContext(AnalyticsContext);
  const { setPaymentState } = useFanModalContext();
  const customerDetails = useCustomerDetails();
  const [error, setError] = useState(false);
  const [support, setSupport]: any = useState(5);

  const handleClick = (songId, playedAt) => {
    setModalState({
      freeRequest: {
        songId,
        modalTitle: playedAt?.length > 0 ? "Repeat A Song" : "Request A Song",
        description: event?.freeRequest?.modalDescription,
      },
    });
  };

  useEffect(() => {
    if (myRequests?.length) {
      const newPlays = myRequests.reduce((prev, r) => {
        if (
          r.songId &&
          songs.find((s) => s._id === r.songId)?.playedAt?.length > 0 &&
          !notifications[r.songId]?.ack
        ) {
          prev.push(r);
        }
        return prev;
      }, []);
      if (newPlays.length) {
        const handleAck = () => {
          const ackd = { ...notifications };
          newPlays.map((p) => {
            ackd[p?.songId] = { ack: true };
          });
          setNotifications(ackd);
          toastId.current = null;
        };
        const handleSubmit = () => {
          logClick({
            label: `Show Your Support`,
            action: window.location.href,
          });
          const numberValue =
            typeof support === "string" ? parseFloat(support) : support;
          const amount = parseInt((numberValue || 0).toFixed(2));
          if (support >= 1) {
            setPaymentState({
              paymentForm: {
                amount,
                type: "donate",
                eventId: event?._id,
                ...customerDetails,
              },
            });
            setSupport(5);
            toast.dismiss(toastId.current);
          } else {
            setError(true);
          }
        };
        const a = newPlays.map(
          (p) => songs.find((s) => s._id === p.songId).title
        );
        let content = (
          <div style={{ position: "relative" }}>
            <Link
              style={{ position: "absolute", right: "12px", top: "0px" }}
              onClick={() => toast.dismiss()}
            >
              Cancel
            </Link>
            <H4 style={{ marginBottom: "18px", paddingTop: "28px" }}>
              Thank {event?.artistName} for playing{" "}
              {a.length === 1
                ? a[0]
                : [a.slice(0, a.length - 1).join(", "), a[a.length - 1]].join(
                    " and "
                  )}
            </H4>
            <div style={{ display: "flex", marginTop: "6px" }}>
              <StyledInput
                leftIcon={<Icon form name="USD" />}
                value={support}
                onChange={(value) => {
                  setSupport(value);
                  setError(false);
                }}
                error={error}
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
              />{" "}
              <StyledButton
                iconLeft={<Icon tertiary name="Charity" />}
                onClick={handleSubmit}
              >
                {event?.customizations?.support?.buttonText ??
                  "Show Your Support"}
              </StyledButton>
            </div>
          </div>
        );
        if (toastId.current) {
          toast.update(toastId.current, {
            render: content,
            onClose: handleAck,
          });
        } else {
          const id = toast(content, {
            autoClose: false,
            position: "bottom-left",
            onClose: handleAck,
            closeOnClick: false,
            closeButton: false,
          });
          toastId.current = id;
        }
      }
    }
  }, [myRequests, songs, support, error]);

  const overrides = event?.customizations?.catalog;

  return (
    <ListWrapper>
      <FlexColumn>
        <H3>{overrides?.title ?? "Our Catalog"}</H3>
        <Subheader>
          {overrides?.description ??
            `Here are the songs you can request ${event?.artistName} to play. Search
          through the list to find the songs you most want to hear!`}
        </Subheader>
        <Input
          value={search}
          onChange={setSearch}
          placeholder="Search for a song by name or artist..."
          leftIcon={<Icon form name="Search" />}
          style={{ marginTop: "16px" }}
          rightIcon={
            search?.length > 0 ? (
              <Remove
                form
                name="RemoveCircle"
                onClick={() => {
                  setSearch("");
                }}
              />
            ) : undefined
          }
        />
      </FlexColumn>
      <List>
        {songs
          ?.filter(({ title, artist }) => textCompare(title, artist, search))
          .sort((a, b) => a.title.localeCompare(b.title))
          .map(
            ({ _id: songId, title, artist, playedAt, accumAmount }, index) => {
              const hasPlayed = playedAt?.length > 0;
              const { minutes } =
                hasPlayed && getTimeSince(playedAt[playedAt?.length - 1], time);
              return (
                <SongWrapper key={index} played={hasPlayed}>
                  <FlexColumn flex="1 1 100%" xStart>
                    <Title yCenter>
                      <P>{title}</P>
                    </Title>
                    <ArtistName style={{ width: hasPlayed ? "80%" : "100%" }}>
                      {artist}
                    </ArtistName>
                  </FlexColumn>
                  <FlexColumn flex="1 1 auto" xEnd>
                    {myRequests?.find((r) => r.songId === songId) ? (
                      <ActionButton disabled isReadOnly played={hasPlayed}>
                        VOTE CAST
                      </ActionButton>
                    ) : (
                      <ActionButton
                        played={hasPlayed}
                        onClick={() => handleClick(songId, playedAt)}
                      >
                        {hasPlayed && <Icon tertiary name="Refresh" />}
                        {!!hasPlayed
                          ? overrides?.actionRepeatText ?? "Repeat"
                          : overrides?.actionText ?? "Request"}
                      </ActionButton>
                    )}
                    {!!hasPlayed && (
                      <LastPlayed>
                        Played{" "}
                        {minutes > 59
                          ? `more than 1 hr. ago`
                          : minutes < 1
                          ? "less than 1 min. ago"
                          : `${minutes} min. ago`}
                      </LastPlayed>
                    )}
                  </FlexColumn>
                </SongWrapper>
              );
            }
          )}
      </List>
    </ListWrapper>
  );
};

const StyledButton = styled(Button)`
  margin-left: 8px;
  width: 100%;
  max-width: 288px;
`;

const StyledInput = styled(Input)`
  text-align: left;
  padding-left: 30px;
  flex-grow: 1;
`;

const Song = styled(P)`
  font-weight: 600;
  letter-spacing: 0.8px;
  margin-bottom: 8px;
`;

export const ListWrapper = styled(FlexColumn)`
  padding: 24px 0 80px 16px;
  & > ${FlexColumn}:first-of-type {
    padding: 0 16px 16px 0;
  }
  ${H3} {
    color: ${(props) => props.theme.branding.event.colors.title};
    font-size: 17px;
    font-family: ${(props) => props.theme.branding.headerFont};
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
  }
  ${Subheader} {
    color: ${(props) => props.theme.branding.event.colors.description};
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
  }
`;

const List = styled(FlexColumn)`
  width: 100%;
`;

const Title = styled(FlexRow)`
  flex-grow: 1;
`;

export const LastPlayed = styled(Footnote)`
  color: ${(props) => props.theme.branding.event.colors.listSubtitle};
  display: flex;
  justify-content: flex-end;
  text-align: right;
  white-space: nowrap;
  width: 84px;
`;

export const ArtistName = styled(Footnote)`
  color: ${(props) => props.theme.branding.event.colors.listSubtitle};
  font-style: normal;
  font-weight: 600;
`;

export const SongWrapper = styled(FlexRow)`
  align-items: flex-start;
  padding: 18px 16px 18px 0;
  width: 100%;
  & + & {
    border-top: 1px solid
      ${(props) => props.theme.branding.event.colors.listDivider};
  }
  & > ${FlexRow} {
    margin-bottom: 4px;
  }
  ${P} {
    color: ${(props) => props.theme.branding.event.colors.listTitle};
    font-size: 15px;
    line-height: 19px;
    font-weight: 600;
  }
  ${Footnote} {
    margin-top: 4px;
  }
`;

export const Remove = styled(Icon)`
  path {
    fill: ${(props) => props.theme.branding.colors.input.placeholder};
  }
`;
