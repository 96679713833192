import type {
  Query,
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
} from "firebase/firestore";

import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { Model, getErrors, isValid } from "@musicaudienceexchange/toccata";

export type DocumentModel<R extends Model> = R & {
  /**
   * Firestore ID of the document.
   */
  _id: string;

  /**
   * Firestore document reference.
   */
  _ref: DocumentReference;
};

/**
 * Loads the given `model` using data from the supplied Firestore document
 * `doc` and returns a hybrid object that is an instance of the supplied
 * `model` merged with the document's ID and ref.
 */
const createDocumentModel = <R extends typeof Model>(
  model: R,
  doc: DocumentSnapshot
): DocumentModel<InstanceType<R>> => {
  const item = model.raw(doc.data(), { silent: true });

  const errors = getErrors(item);
  if (errors.length > 0) {
    if (process.env.REACT_APP_STAGE !== "production") {
      console.error(`Invalid document at path: ${doc.ref.path}`, errors);
    }
  }

  Object.defineProperties(item, {
    _id: { value: doc.id, writable: false, enumerable: true },
    _ref: { value: doc.ref, writable: false, enumerable: true },
  });

  return item as DocumentModel<InstanceType<R>>;
};

/**
 * Listen to a collection query and return validated instances of the supplied
 * Toccata model.
 */
export const useCollectionModel = <R extends typeof Model>(
  model: R,
  query: Query | undefined
): [
  DocumentModel<InstanceType<R>>[] | undefined,
  boolean,
  FirestoreError | undefined
] => {
  const [data, loading, error] = useCollection(query);

  const items = data?.docs
    .map((doc) => createDocumentModel(model, doc))
    .filter(isValid);

  return [items, loading, error];
};

/**
 * Listen to document changes and return a validated instance of the supplied
 * Toccata model.
 */
export const useDocumentModel = <R extends typeof Model>(
  model: R,
  docRef: DocumentReference
): [
  DocumentModel<InstanceType<R>> | undefined,
  boolean,
  FirestoreError | undefined
] => {
  const [doc, loading, error] = useDocument(docRef);

  const item =
    doc && doc.exists() ? createDocumentModel(model, doc) : undefined;

  return [item && isValid(item) ? item : undefined, loading, error];
};
