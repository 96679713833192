import styled, { css } from "styled-components";
import { FlexRow, P, Icon } from "notes";
import { useFanEventContext } from ".";
import { useAnalytics } from "Hooks";
import { IconUser } from "Images";

const ViewPropTypes = [
  "requests",
  "playlist",
  "sponsor",
  "encore",
  "html",
  "checkIn",
  "freeRequest",
  "bio",
] as const;

export type ViewProps = typeof ViewPropTypes[number];

interface NavProps {
  iconActive: any;
  iconInactive: any;
  label: string;
  name: ViewProps;
}

export const Navigation = () => {
  const { logClick } = useAnalytics();
  const { setView, view, event } = useFanEventContext();

  const bioEnabled = event?.bio?.enabled;

  let navItems: NavProps[] = [
    {
      iconActive: <Icon form name="Record" />,
      iconInactive: <Icon form name="Record" />,
      label: event.customizations?.catalog?.tab ?? "Catalog",
      name: "playlist",
    },
    {
      iconActive: <Icon tertiary name="Music" />,
      iconInactive: <Icon form name="Music" />,
      label: event.customizations?.vote?.tab ?? "Top Requests",
      name: "requests",
    },
  ];

  if (bioEnabled) {
    navItems.splice(1, 1, {
      iconActive: <IconUser />,
      iconInactive: <IconUser />,
      label: event?.bio?.tabName || `About ${event?.artistName}`,
      name: "bio",
    });
  }

  if (event?.type === "encore") {
    const hasCustomTab = !!Object.keys(event?.customTab ?? {})?.length;
    if (hasCustomTab) {
      const tabIcon = event?.customTab?.tabIcon;
      navItems = [
        {
          iconActive: <Icon tertiary name="Music" />,
          iconInactive: <Icon form name="Music" />,
          label: event.customizations?.encore?.tab ?? "Fan Favorite",
          name: "encore",
        },
        {
          label: event?.customTab?.tabLabel ?? "Merch",
          iconActive: tabIcon?.darkPath ? (
            <CustomIcon src={tabIcon?.darkPath} alt="" />
          ) : (
            <Icon tertiary name="Gift" />
          ),
          iconInactive: tabIcon?.lightPath ? (
            <CustomIcon src={tabIcon?.lightPath} alt="" />
          ) : (
            <Icon tertiary name="Gift" />
          ),
          name: "html",
        },
      ];
    } else {
      return null;
    }
  }

  if (event?.type === "freeRequest") {
    return null;
  }

  if (event?.type === "checkIn") {
    const hasCustomTab = !!Object.keys(event?.customTab ?? {})?.length;
    if (hasCustomTab) {
      const checkIn = event?.checkIn;
      const tabIcon = event?.customTab?.tabIcon;
      navItems = [
        {
          iconActive: <Icon tertiary name="Edit" />,
          iconInactive: <Icon tertiary name="Edit" />,
          label: checkIn?.tab ?? "Check In",
          name: "checkIn",
        },
        {
          label: event?.customTab?.tabLabel ?? "Merch",
          iconActive: tabIcon?.darkPath ? (
            <img src={tabIcon?.darkPath} alt="" />
          ) : (
            <Icon tertiary name="Gift" />
          ),
          iconInactive: tabIcon?.lightPath ? (
            <img src={tabIcon?.lightPath} alt="" />
          ) : (
            <Icon tertiary name="Gift" />
          ),
          name: "html",
        },
      ];
    } else {
      return null;
    }
  }

  if (event?.type === "sponsor") {
    const hasEncore = !!Object.keys(event?.encore ?? {})?.length;
    if (hasEncore) {
      navItems = [
        {
          iconActive: <Icon form name="Record" />,
          iconInactive: <Icon form name="Record" />,
          label: event.customizations?.sponsor?.tab ?? "Sponsor",
          name: "sponsor",
        },
        {
          iconActive: <Icon tertiary name="Music" />,
          iconInactive: <Icon form name="Music" />,
          label: event.customizations?.encore?.tab ?? "Fan Favorite",
          name: "encore",
        },
      ];
    } else {
      return null;
    }
  }

  const handleOptionClick = (name: ViewProps, label: string) => {
    logClick({
      action: `fan_tab_navigation_${name}`,
      eventId: event._id,
      label,
    });
    setView(name);
  };

  return (
    <Container>
      <OptionWrapper>
        {navItems.map(({ label, name, iconActive, iconInactive }) => (
          <Option
            key={name}
            active={view === name}
            onClick={() => handleOptionClick(name, label)}
          >
            {view === name ? iconActive : iconInactive}
            <P>{label}</P>
          </Option>
        ))}
        <OptionBackground />
      </OptionWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.branding.event.colors.page};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
`;

const OptionWrapper = styled(FlexRow)`
  position: relative;
`;

const OptionBackground = styled(FlexRow)`
  background-color: ${(props) =>
    props.theme.branding.event.colors.tabs.inactiveBackground};
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 3px;
  z-index: 0;
  height: 100%;
  width: calc(100% - 6px);
  img {
    width: 14px;
    height: 14px;
  }
`;

const Option = styled(FlexRow)`
  background-color: ${(props) =>
    props.theme.branding.event.colors.tabs[
      props.active ? "activeBackground" : "inactiveBackground"
    ]};
  border-radius: 20px;
  cursor: pointer;
  padding: 0 12px;
  position: relative;
  white-space: nowrap;
  height: 36px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  svg {
    width: 14px;
    height: 14px;
    path {
      fill: ${(props) =>
        props.active
          ? props.theme.branding.event.colors.tabs.activeText
          : props.theme.branding.event.colors.tabs.inactiveText};
    }
  }
  ${P} {
    color: ${(props) =>
      props.active
        ? props.theme.branding.event.colors.tabs.activeText
        : props.theme.branding.event.colors.tabs.inactiveText};
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-left: 6px;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${(props) =>
    props.expand &&
    css`
      ${P} {
        font-size: 10px;
      }
    `};
`;

const CustomIcon = styled.img`
  max-height: 20px;
  max-width: 20px;
  object-fit: contain;
`;
