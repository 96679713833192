import styled from "styled-components";
import { FlexColumn, H3, Subheader, Input, Icon } from "notes";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { UpdatedEventSongModel, useArtistEventContext } from "../../Components";
import { textCompare } from "Utils";
import { useState } from "react";
import { updateFirestoreDoc } from "firebase-internal";
import { ListItemSwipe } from "./ListItemSwipe";
import { ListItemStatic } from "./ListItemStatic";
import { updatePriority } from "./updatePriority";

const SortContainer = styled(FlexColumn)``;

const SortableList = SortableContainer(
  ({ songs, eventId }: { songs: UpdatedEventSongModel[]; eventId: string }) => {
    const SortableRequest = SortableElement(ListItemSwipe);
    return (
      <SortContainer>
        {songs.map((song, index) => (
          <SortableRequest
            eventId={eventId}
            key={`request-${index}`}
            song={song}
            index={index}
          />
        ))}
      </SortContainer>
    );
  }
);

export const SetList = () => {
  const {
    event,
    playlist: songs,
    setPriority,
    priority,
  } = useArtistEventContext();
  const [search, setSearch] = useState("");

  const playlist = songs?.filter(
    ({ title, artist, encore }) => !encore && textCompare(title, artist, search)
  );

  const unplayedSongs =
    priority &&
    playlist
      ?.filter((playlist) => !playlist?.playedAt?.length)
      ?.sort((a, b) => (priority[a?._id] > priority[b?._id] ? 1 : -1));

  let songsWithRequests: UpdatedEventSongModel[] = [];
  let sortableSongs: UpdatedEventSongModel[] = [];

  if (event?.type === "vote") {
    songsWithRequests = unplayedSongs
      ?.filter(({ requests }) => requests?.length > 0)
      .sort((a, b) => (a.accumAmount > b.accumAmount ? -1 : 1));
    sortableSongs = unplayedSongs?.filter(
      ({ requests }) => requests?.length === 0
    );
  } else if (event?.type === "freeRequest") {
    songsWithRequests = unplayedSongs
      ?.filter(({ requests }) => requests?.length > 0)
      .sort((a, b) => (a.requests.length > b.requests.length ? -1 : 1));
    sortableSongs = unplayedSongs?.filter(
      ({ requests }) => requests?.length === 0
    );
  } else {
    sortableSongs = unplayedSongs;
  }

  const playedSongs = playlist?.filter(
    (playlist) => !!playlist?.playedAt?.length
  );

  const declinedSongs = playlist?.filter((song) => song.declined);

  const handleSort = (value) => {
    const { localSongs, priority, currSongId } = updatePriority(
      value,
      songs,
      sortableSongs
    );
    const priorityMap = localSongs?.reduce((map, { _id, priority }) => {
      map[_id] = priority;
      return map;
    }, {});
    setPriority(priorityMap);
    updateFirestoreDoc(`nextsong_events/${event._id}/playlist/${currSongId}`, {
      priority,
    });
    return;
  };

  return (
    <>
      <FilterContainer>
        <InputWrapper>
          <StyledInput
            leftIcon={<Icon form name="Search" />}
            rightIcon={
              search?.length > 0 ? (
                <Remove
                  form
                  name="RemoveCircle"
                  onClick={() => {
                    setSearch("");
                  }}
                />
              ) : undefined
            }
            placeholder="Search for a song by name or artist..."
            value={search}
            onChange={setSearch}
          />
        </InputWrapper>
      </FilterContainer>
      <SectionRequests>
        <List>
          {songsWithRequests?.map((song, index) => (
            <ListItemSwipe
              eventId={event?._id}
              key={`request-${index}`}
              song={song}
              index={index}
            />
          ))}
          {!!songsWithRequests?.length && !!sortableSongs?.length && (
            <Divider />
          )}
          {!!sortableSongs?.length && (
            <SortableList
              songs={sortableSongs}
              useDragHandle
              lockToContainerEdges
              lockAxis={"y"}
              onSortEnd={handleSort}
              eventId={event?._id}
            />
          )}
          {playedSongs?.map((song, index) => (
            <ListItemStatic key={`played-${index}`} song={song} />
          ))}
          {declinedSongs?.map((song, index) => (
            <ListItemStatic key={`declined-${index}`} song={song} declined />
          ))}
        </List>
      </SectionRequests>
    </>
  );
};

const SectionRequests = styled(FlexColumn)`
  width: 100%;
  max-width: 600px;
  padding: 0 0 80px 0;
  ${H3} {
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    margin-bottom: 4px;
  }
  ${Subheader} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 16px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-top: 8px;
    padding: 8px 16px 80px 16px;
  }
`;

const List = styled(FlexColumn)`
  width: 100%;
  height: 100%;
`;

const FilterContainer = styled(FlexColumn)`
  width: 100%;
  background: #ffffff;
  border-bottom: 2px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 4px;
  position: sticky;
  top: 0;
  z-index: 200;
  align-items: center;
`;

const InputWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 600px;
`;

const StyledInput = styled(Input)`
  border: none;
  width: 100%;
  &:focus {
    box-shadow: none;
  }
`;

const Remove = styled(Icon)`
  color: ${(props) => props.theme.palette.black};
`;

const Divider = styled.div`
  display: block;
  height: 4px;
  width: 100%;
  background-color: #fafafa;
  box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.24);
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-top: 12px;
    margin-bottom: 4px;
    border-radius: 2px;
    box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.18);
  }
`;
