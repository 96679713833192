import {
  Subheader,
  H3,
  H4,
  P,
  FlexColumn,
  FlexRow,
  Flex,
  Button as ButtonBase,
  Select as SelectBase,
  Input,
  Icon,
  Label,
  DatePicker,
} from "notes";
import { Header as HeaderBase, PageModal } from "Components";
import { useArtistModalContext } from "../Components";
import styled from "styled-components";
import { useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import { useArtistEventContext } from "../Components/ArtistEventContext";
import { ModalAddEditSong } from "./ModalAddEditSong";
import { updateFirestoreDoc } from "firebase-internal";

export const ModalEditEvent = () => {
  const { modal } = useArtistModalContext();
  const isOpen = !!modal?.editEvent;
  return (
    <PageModal
      open={isOpen}
      header={<Header isArtist tertiary hideMenu />}
      title="Edit Event"
    >
      <EditEvent />
    </PageModal>
  );
};

interface FormProps {
  status?: string;
  startDate?: Date;
  startTime?: string;
  startsAt?: Date;
}

export const EditEvent = () => {
  const { setModal, modal, setDialog, setNotification } =
    useArtistModalContext();
  const {
    playlist,
    event: { _id: eventId, status, startsAt, venue, type },
  } = useArtistEventContext();
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const startDate = startsAt?.toJSDate();
  const startTime = startsAt?.toFormat("HH:mm");

  const [form, setForm] = useState<FormProps>({
    startDate,
    startTime,
    status,
  });

  const { app } = getAuth();
  const db = getFirestore(app);

  const handleClose = () => {
    setModal(null);
  };

  const statusOptions = [
    { text: "Unpublished", id: 1 },
    { text: "Published", id: 2 },
  ];

  const handleChange = (name, value) => {
    setIsDirty(true);
    setForm({ ...form, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    const ref = doc(db, `nextsong_events/${eventId}`);
    let payload: FormProps = {};
    if (form?.status) {
      payload.status = form.status;
    }
    if (form?.startDate && form?.startTime) {
      const timeParts = form?.startTime.split(":");
      const luxonTime = DateTime.fromJSDate(form?.startDate);
      const updatedTime = luxonTime.set({
        hour: parseInt(timeParts[0]),
        minute: parseInt(timeParts[1]),
      });
      payload.startsAt = updatedTime?.toJSDate();
    }
    try {
      await updateDoc(ref, { ...payload });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    handleClose();
    if (isDirty) {
      setNotification({
        type: "success",
        title: "Success!",
        message: "Changes have been saved",
        timeout: 3500,
      });
    }
  };

  const handleDelete = async (songId) => {
    setDialog({
      title: "Are you sure you want to delete this song?",
      type: "delete",
      actions: {
        submit: "Delete Song",
      },
      onConfirm: async () => {
        await updateFirestoreDoc(
          `nextsong_events/${eventId}/playlist/${songId}`,
          null,
          { deleteDoc: true }
        );
        setNotification({
          type: "success",
          title: "Success!",
          message: `Song was deleted.`,
          timeout: 3500,
        });
        setDialog(null);
      },
    });
  };

  const selected = statusOptions.find(
    ({ text }) => text.toLowerCase() === form?.status?.toLowerCase()
  );

  return (
    <>
      <ModalAddEditSong />
      <Wrapper>
        <Container column>
          <H3>Edit Event</H3>
          <Subheader>{venue}</Subheader>
          <Box>
            <H4>Event Status</H4>
            <Label>Status</Label>
            <Select
              selected={selected}
              options={statusOptions}
              onChange={(v) => handleChange("status", v.text?.toLowerCase())}
            />
          </Box>
          <Box>
            <H4>Start Date</H4>
            <Label>Date</Label>
            <DatePicker
              leftIcon={<Icon form name="Calendar" />}
              onSelect={(v) => handleChange("startDate", v)}
              selected={form?.startDate}
              placeholder="Date"
            />
            <Label>Time</Label>
            <Input
              leftIcon={<Icon form name="Clock" />}
              onChange={(v) => {
                console.log("v", v);
                handleChange("startTime", v);
              }}
              value={form?.startTime}
              placeholder="Time"
              type="time"
            />
          </Box>
          {!["checkIn", "donate"].includes(type) && (
            <Box style={{ padding: "20px 0 0" }}>
              <FlexRow
                style={{
                  flexGrow: 1,
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  padding: "0 24px",
                }}
              >
                <H4>Catalog</H4>
                <Button
                  style={{ flexGrow: 0, padding: 0 }}
                  type="link"
                  iconLeft={<Icon tertiary name="Add" />}
                  onClick={() => setModal({ ...modal, addSong: {} })}
                >
                  Add Songs
                </Button>
              </FlexRow>
              {!!playlist?.length && (
                <ListContainer>
                  {playlist?.map((song, index) => (
                    <ListItem key={index}>
                      <FlexColumn flex="1 1 100%">
                        <P>{song.title}</P>
                        <P>{song.artist}</P>
                      </FlexColumn>
                      <ActionIcon
                        tertiary
                        name="Pencil"
                        onClick={() =>
                          setModal({
                            ...modal,
                            addSong: { songId: song._id, ...song },
                          })
                        }
                      />
                      <ActionIcon
                        tertiary
                        name="Delete"
                        onClick={() => handleDelete(song._id)}
                      />
                    </ListItem>
                  ))}
                </ListContainer>
              )}
            </Box>
          )}
        </Container>
        <PageActions>
          <Container row>
            <Button type="tertiary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                isDirty ? handleSave() : handleClose();
              }}
              loading={loading}
            >
              {isDirty ? "Save" : "Done"}
            </Button>
          </Container>
        </PageActions>
      </Wrapper>
    </>
  );
};

const ActionIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.palette.action.primary};
  & + & {
    color: ${(props) => props.theme.palette.red.primary};
    margin-left: 20px;
  }
`;

const Container = styled(Flex)`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;

const ListContainer = styled(FlexColumn)``;

const ListItem = styled(FlexRow)`
  align-items: center;
  padding: 10px 24px 8px;
  & + & {
    border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  }
  ${P} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 8px;
  }
  ${P}:first-child {
    color: ${(props) => props.theme.palette.black}cc;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
  }
  ${P}:last-child {
    color: ${(props) => props.theme.palette.black};
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }
`;

const Box = styled(FlexColumn)`
  background-color: #f2f5f7;
  border-radius: 8px;
  padding: 20px 24px;
  div + ${Label} {
    margin-top: 16px;
  }
  & + & {
    margin-top: 24px;
  }
`;

const Wrapper = styled(FlexColumn)`
  margin-top: 40px;
  padding: 16px 24px 112px;
  position: relative;
  ${H3} {
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
  }
  ${Subheader} {
    margin-bottom: 16px;
  }
  ${H4} {
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

const Header = styled(HeaderBase)`
  position: fixed;
  top: 0;
  left: 0;
  & > div {
    padding: 0 24px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    & > div {
      padding: 0;
    }
  }
`;

const PageActions = styled(FlexRow)`
  background-color: #ffffff;
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 12px 24px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Button = styled(ButtonBase)`
  min-width: 0;
  flex-grow: 1;
  & + & {
    margin-left: 16px;
  }
`;

const Select = styled(SelectBase)`
  z-index: 1;
`;
