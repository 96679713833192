import styled, { css } from "styled-components";
import { FlexColumn, FlexRow, Icon } from "notes";
import { VideoPlayer } from ".";
import { useState, useRef } from "react";
import { ReactComponent as IconPlay } from "../assets/IconPlay.svg";
import { ReactComponent as IconPause } from "../assets/IconPause.svg";
import logoWhite from "Images/nextsong-white.png";
import about_video_thumbnail from "Images/about-video-thumbnail.jpg";

export const Video: React.FC<{ path: string | string[] }> = ({ path }) => {
  const [play, setPlay] = useState(false);
  const [pause, setPause] = useState(false);
  const [ended, setEnded] = useState(false);
  const [volume, setVolume] = useState(70);
  const [volumeOpen, setVolumeOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const playerRef: any = useRef();

  const handlePlay = () => {
    setPause(false);
    setEnded(false);
    setPlay(true);
  };

  const handleSeek = (value) => {
    if (!!playerRef?.current) {
      playerRef?.current?.seekTo(parseFloat(value), "fraction");
    }
  };

  return (
    <Wrapper>
      <VideoContainer ended={ended}>
        {((!play && !pause) || ended) && (
          <ActionOverlay xCenter yCenter play={play} onClick={handlePlay}>
            {ended ? <Icon name="Refresh" /> : <Icon media name="Play" />}
          </ActionOverlay>
        )}
        <VideoWrapper>
          <VideoPlayer
            mute={false}
            play={play}
            path={path}
            onEnded={() => {
              setEnded(true);
              setPlay(false);
            }}
            thumbnail={about_video_thumbnail}
            onProgress={({ played }) => setProgress(played * 100)}
            onPause={() => setPause(true)}
            volume={volume / 100}
            ref={playerRef}
          />
        </VideoWrapper>
        <Controls yCenter pause={pause && !ended}>
          <TogglePlay
            xCenter
            yCenter
            onClick={() => {
              if (play) {
                setPlay(false);
              } else {
                handlePlay();
              }
            }}
          >
            {ended ? (
              <ReplayIcon tertiary name="Refresh" />
            ) : play ? (
              <IconPause />
            ) : (
              <IconPlay />
            )}
          </TogglePlay>
          <ProgressContainer yCenter>
            <ProgressSlider
              onChange={(e) => handleSeek(parseInt(e.target.value) / 100)}
              value={progress}
              type="range"
              min="0"
              max="100"
              step="1"
            />
            <SlideProgress percent={progress} />
            <SlideTrack />
          </ProgressContainer>
          <VolumeContainer
            yCenter
            onMouseEnter={() => setVolumeOpen(true)}
            onMouseLeave={() => setVolumeOpen(false)}
          >
            <VolumeIcon
              tertiary
              name={volume > 0 ? "VolumeOn" : "VolumeOff"}
              onClick={() => setVolume(volume > 0 ? 0 : 50)}
            />
            <VolumeHideContainer volumeOpen={volumeOpen}>
              <VolumeSlider
                onChange={(e) => setVolume(parseInt(e.target.value))}
                value={volume}
                type="range"
                min="0"
                max="100"
                step="1"
              />
              <SlideProgress percent={volume} />
              <SlideTrack />
            </VolumeHideContainer>
          </VolumeContainer>
          <StyledLogo src={logoWhite} alt="NextSong" />
        </Controls>
      </VideoContainer>
    </Wrapper>
  );
};

const StyledLogo = styled.img`
  width: 79px;
  opacity: 0.4;
  margin-left: 10px;
  margin-bottom: -4px;
  object-fit: contain;
`;

const ReplayIcon = styled(Icon)`
  color: #ffffff;
  width: 16px;
  height: 16px;
`;

const VolumeIcon = styled(ReplayIcon)`
  cursor: pointer;
  margin-right: 8px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

const ProgressContainer = styled(FlexRow)`
  position: relative;
  flex-grow: 1;
  margin: 0 16px 0 20px;
  height: 4px;
  transition: opacity 0.2s ease-out;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin: 0 8px 0 12px;
  }
`;

const Controls = styled(FlexRow)`
  background: linear-gradient(155deg, rgb(255, 255, 255, 0.15), transparent);
  backdrop-filter: blur(20px);
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  justify-content: space-between;
  padding: 8px 16px 10px 12px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 55;
  ${(props) =>
    !props.pause &&
    css`
      visibility: hidden;
      opacity: 0;
    `};
  transition: all 0.2s ease-out;
`;

const SlideProgress = styled.div<any>`
  background: #ffffff80;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.percent}%;
  height: 4px;
  z-index: 10;
`;

const SlideTrack = styled.div`
  background: #ffffff40;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: 9;
`;

const TogglePlay = styled(FlexRow)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: opacity 0.2s ease-out;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

const VolumeSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
  outline: none;
  position: relative;
  z-index: 11;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    background: #f7f7f5;
    border-radius: 50%;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }

  &::-moz-range-thumb {
    width: 14px;
    height: 14px;
    background: #f7f7f5;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
`;

const ProgressSlider = styled(VolumeSlider)`
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 8px;
    background: #f7f7f5;
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }

  &::-moz-range-thumb {
    width: 8px;
    height: 8px;
    background: #f7f7f5;
    cursor: pointer;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
`;

const Wrapper = styled(FlexColumn)`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

const VolumeContainer = styled(FlexRow)`
  height: 24px;
  &:hover {
    opacity: 1;
  }
  padding-left: 10px;
  padding-right: 10px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const VolumeHideContainer = styled(FlexRow)`
  position: relative;
  width: ${(props) => (props.volumeOpen ? "80px" : 0)};
  visibility: ${(props) => (props.volumeOpen ? "visible" : "hidden")};
  opacity: ${(props) => (props.volumeOpen ? 1 : 0)};
  transition: all 0.2s ease-out;
`;

const VideoContainer = styled(FlexColumn)`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  ${(props) =>
    !props.ended &&
    css`
      &:hover {
        ${Controls} {
          visibility: visible;
          opacity: 1;
        }
      }
    `};
`;

const ActionOverlay = styled(FlexRow)`
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 50;
  transition: all 0.2s ease-in-out;
  svg {
    width: 52px;
    height: 52px;
    opacity: ${(props) => (props.play ? 0 : 1)};
    transition: all 0.2s ease-in-out;
    path {
      transition: all 0.2s ease-in-out;
      fill: rgba(255, 255, 255, 0.7);
    }
  }
  &:hover {
    background: rgba(0, 0, 0, 0.12);
    svg {
      opacity: 1;
      path {
        fill: rgba(255, 255, 255, 1);
      }
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    svg {
      width: 64px;
      height: 64px;
    }
  }
  ${(props) =>
    props.play &&
    css`
      background: rgba(0, 0, 0, 0);
    `};
`;

const VideoWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;
