import { useContext, useEffect, useState } from "react";
import styled, { css, ThemeContext } from "styled-components";
import {
  FlexColumn,
  Label,
  Icon,
  H3,
  P,
  FlexRow,
  CreditCardBadge,
  FieldMessage,
  Input,
} from "notes";
import {
  PayButton,
  PaymentRequestButton,
  PaymentError,
  useAnalytics,
} from "Hooks";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useElements, CardElement } from "@stripe/react-stripe-js";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import { Drawer } from "Components";
import { useFanModalContext } from ".";
import { useFanEventContext } from "./FanEventContext";

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

export const ModalPayment = ({ open }) => {
  const { setPaymentState } = useFanModalContext();
  return (
    <Drawer open={open} onClose={() => setPaymentState(null)}>
      <PaymentForm />
    </Drawer>
  );
};

const PaymentForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentFormComponent {...props} />
    </Elements>
  );
};

export const PaymentFormComponent = () => {
  const { logClick, logPaymentSuccess } = useAnalytics();
  const { event } = useFanEventContext();
  const { paymentState, setPaymentState } = useFanModalContext();
  const passedData = paymentState?.paymentForm;
  const savedPaymentMethod = passedData?.paymentMethod;
  const paymentDetails = {
    ...passedData,
    amount: passedData?.amount * 100,
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [payment, setPayment] =
    useState<nextsong.functions.PaymentInitRequest["payment"]>(paymentDetails);

  const [editName, setEditName] = useState(false);
  const [editCreditCard, setEditCreditCard] = useState(false);

  const elements = useElements();

  const onError = (error: PaymentError) => {
    if (error?.fields?.length > 0) {
      setError({
        message: "The highlighted fields need to be valid.",
        fields: error.fields,
      });
    } else {
      setError({ message: "There was an error processing your payment." });
    }
  };

  const onSuccess = () => {
    const { type } = passedData;
    if (type) {
      logPaymentSuccess({
        label: `${type}`,
        eventId: event._id,
        amount: payment.amount,
      });
    }
    if (["sponsor", "encore"].includes(passedData.type)) {
      setPaymentState(null);
    } else {
      setPaymentState({
        paymentSuccess: {
          displayName:
            payment["displayName"] || payment?.name?.split(" ", 1)?.toString(),
          type: passedData?.type,
          songId: passedData?.songId,
        },
      });
    }
  };

  const card = elements?.getElement(CardElement);

  useEffect(() => {
    if (!!card) {
      card?.on("change", function (event) {
        setError(null);
      });
    }
  }, [card]);

  const hasSavedContact = passedData?.name && passedData?.email && !editName;

  const { branding } = useContext(ThemeContext);

  const cardElementStyles: any = {
    classes: {
      base: "stripe-credit-card",
      complete: "stripe-credit-card--complete",
      focus: "stripe-credit-card--focus",
      invalid: "stripe-credit-card--invalid",
    },
    style: {
      base: {
        backgroundColor: branding.colors.input.background,
        iconColor: "#A6AEB2",
        fontFamily: `${branding.colors.input.font}, sans-serif`,
        fontSize: "17px",
        fontSmoothing: "antialiased",
        lineHeight: "22px",
        fontWeight: 400,
        color: branding.colors.input.text,
        boxSizing: "border-box",
        "::placeholder": {
          fontFamily: `${branding.colors.input.placeholderFont}, sans-serif`,
          fontSize: "16px",
          lineHeight: "22px",
          fontWeight: 400,
          color: branding.colors.input.placeholder,
          fontSmoothing: "antialiased",
        },
      },
      invalid: {
        color: "#E45C52",
      },
    },
    disabled: false,
  };

  const handlePaymentProcessing = (bool: boolean) => {
    if (bool) {
      const { type } = passedData;
      logClick({
        action: `submitting_${type}_payment`,
        eventId: event._id,
      });
    }
    setLoading(bool);
  };

  return (
    <Wrapper>
      <SubtotalContainer>
        <LabelLight>Subtotal</LabelLight>
        <P>${paymentState?.paymentForm?.amount?.toFixed(2)}</P>
      </SubtotalContainer>
      <TotalRow>
        <LabelBold>Pay Total</LabelBold>
        <H3>
          <span>USD</span>${paymentState?.paymentForm?.amount?.toFixed(2)}
        </H3>
      </TotalRow>
      <Line />
      <Form>
        {hasSavedContact ? (
          <FlexRow yCenter>
            <FlexColumn flex="1 1 100%">
              <StyledLabel>{passedData?.name}</StyledLabel>
              <LabelGray>{passedData?.email}</LabelGray>
            </FlexColumn>
            <Icon onClick={() => setEditName(true)} tertiary name="Pencil" />
          </FlexRow>
        ) : (
          <>
            <Title>Contact</Title>
            <Input
              name="name"
              value={payment?.name}
              onChange={(value) => {
                setPayment((p) => ({ ...p, name: value }));
                setError(null);
              }}
              error={error?.fields?.includes("name")}
              placeholder="Name on card..."
            />
            <Input
              name="email"
              value={payment?.email}
              onChange={(value) => {
                setPayment((p) => ({ ...p, email: value }));
                setError(null);
              }}
              error={error?.fields?.includes("email")}
              style={{ marginTop: "12px" }}
              type="email"
              leftIcon={<Icon form name="Email" />}
              placeholder="Email address..."
            />
          </>
        )}
        {error?.message && !!error?.fields && (
          <FieldMessage content={error?.message} />
        )}
      </Form>
      <Line short />
      <Form>
        {savedPaymentMethod && !editCreditCard ? (
          <FlexRow flex="1 0 100%" style={{ justifyContent: "space-between" }}>
            <StyledLabel>
              <CreditCardBadge
                type={savedPaymentMethod?.brand?.toLowerCase()}
              />
              <span>{savedPaymentMethod?.brand}</span> (ending in{" "}
              {savedPaymentMethod?.last4})
            </StyledLabel>
            <Icon
              tertiary
              name="Pencil"
              onClick={() => setEditCreditCard(true)}
            />
          </FlexRow>
        ) : (
          <>
            <Title>
              <Icon tertiary name="Lock" />
              Credit Card
            </Title>
            <CardStyling>
              <CardElement options={cardElementStyles} />
            </CardStyling>
          </>
        )}
        {error?.message && !error?.fields && (
          <FieldMessage content={error?.message} />
        )}
      </Form>
      <Line short />
      <Actions>
        <StyledPayButton
          payment={payment}
          onPaymentComplete={onSuccess}
          onPaymentError={onError}
          onPaymentProcessing={(bool) => handlePaymentProcessing(bool)}
          paymentMethod={savedPaymentMethod?.id ?? { card: card }}
          disabled={!!loading}
          style={{ marginTop: "16px" }}
        >
          Pay
          {savedPaymentMethod && !editCreditCard && (
            <>
              {" "}
              with
              <span>{savedPaymentMethod?.brand}</span>
              <span>(...{savedPaymentMethod?.last4})</span>
            </>
          )}
        </StyledPayButton>
        <PaymentRequestButton
          payment={payment}
          onPaymentComplete={onSuccess}
          onPaymentError={onError}
          onPaymentProcessing={(bool) => handlePaymentProcessing(bool)}
          disabled={!!loading}
        />
      </Actions>
    </Wrapper>
  );
};

const Line = styled(FlexColumn)`
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  width: 100%;
  height: 1px;
  ${(props) =>
    props.short &&
    css`
      margin-left: 16px;
      width: calc(100% - 16px);
    `};
`;

const CardStyling = styled(FlexColumn)`
  width: 100%;
  .stripe-credit-card {
    background-color: ${(props) =>
      props.theme.branding.colors.input.background};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.branding.colors.input.border};
    border-radius: 4px;
    padding: 8px 12px;
    font-family: ${(props) => props.theme.branding.colors.input.font};
    font-size: 17px;
    line-height: 22px;
    box-sizing: content-box;
  }

  .stripe-credit-card--focus {
    border-color: ${(props) => props.theme.branding.colors.action}50;
    box-shadow: 0 0 4px 0 ${(props) => props.theme.branding.colors.action}50;
  }

  .stripe-credit-card--invalid {
    background-color: #faf1f0;
    border-color: #e45c52;
    border-width: 2px;
    box-shadow: none;
    padding: 7px 12px;
  }

  .stripe-credit-card--complete,
  .stripe-credit-card--webkit-autofill {
    border-color: #6dcc6a;
    border-width: 2px;
    box-shadow: none;
    padding: 7px 12px;
  }
`;

const StyledLabel = styled(Label)`
  align-items: center;
  font-weight: 600;
  flex-flow: row nowrap;
  svg {
    margin-right: 12px;
    width: 24px;
  }
  span {
    margin-right: 6px;
    text-transform: capitalize;
  }
`;

const LabelGray = styled(StyledLabel)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-weight: 400;
`;

const Title = styled(Label)`
  align-items: center;
  flex-flow: row nowrap;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  flex-grow: 1;
  margin-bottom: 6px;
  svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
    margin-top: -6px;
  }
`;

const LabelBold = styled(Label)`
  align-items: center;
  flex-flow: row nowrap;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  flex-grow: 1;
`;

const LabelLight = styled(LabelBold)`
  font-weight: 400;
  margin: 0;
`;

const Wrapper = styled(FlexColumn)`
  padding-bottom: 24px;
`;

const SubtotalContainer = styled(FlexRow)`
  margin-left: 16px;
  padding: 16px 24px 16px 8px;
  ${P} {
    font-weight: 700;
  }
`;

const Form = styled(FlexColumn)`
  padding: 16px 24px 16px 24px;
`;

const TotalRow = styled(SubtotalContainer)`
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  ${H3} {
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
  }
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin-right: 6px;
  }
`;

const Actions = styled(SubtotalContainer)`
  flex-direction: column;
  padding-top: 0;
  position: relative;

  apple-pay-button {
    --apple-pay-button-width: 100%;
    --apple-pay-button-height: 40px;
    --apple-pay-button-border-radius: 4px;
    --apple-pay-button-padding: 0px 12px;
    --apple-pay-button-box-sizing: border-box;
  }
`;

const StyledPayButton = styled(PayButton)`
  width: 100%;
  background-color: #0a2540;
  color: #ffffff;
  &&:not(:disabled):hover,
  &&:not(:disabled):focus {
    background-color: #0a2540;
  }
  span {
    margin-left: 4px;
    text-transform: capitalize;
  }
  span + span {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-size: 12px;
    line-height: 15px;
  }
`;
