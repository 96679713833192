import styled from "styled-components";
import { H4, FlexColumn } from "notes";
import { NavHeader, Footer, TermsContent } from "Components";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const Terms = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavHeader secondary onBack={() => history.push("/")} />
      <FormContainer>
        <H4>Terms of Use</H4>
        <TermsContent />
      </FormContainer>
      <Footer />
    </>
  );
};

const FormContainer = styled(FlexColumn)`
  margin: 0 auto;
  width: 100%;
  padding: 40px 0;
  ${H4} {
    font-family: ${(props) => props.theme.branding.headerFont};
    font-size: 20px;
    line-height: 24px;
    margin-left: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;
