import styled, { css } from "styled-components";
import { FlexColumn, H3, H4, Small } from "notes";
import { MapIcon } from "Images";
import { getDateOrdinal } from "Utils";
import { useFanEventContext } from ".";
import { getShades } from "../Bio";

export const Banner = () => {
  const { event } = useFanEventContext();
  const { artistName, startsAt, venue, image, imageBanner } = event;
  const isSecondary = ["encore", "checkIn"].includes(event?.type);
  return (
    <Wrapper xCenter yEnd isSecondary={isSecondary}>
      <TopGradient />
      <Content>
        <H3>{artistName}</H3>
        <Divider />
        <H4>
          <IconMap /> {venue}
        </H4>
        <Small>
          {startsAt?.toFormat("cccc, LLLL d")}
          {getDateOrdinal(startsAt?.day)} ({startsAt?.toFormat("h:mm")}
          <span>{startsAt?.toFormat("a").toLowerCase()}</span>{" "}
          {startsAt?.toFormat("ZZZZ")})
        </Small>
      </Content>
      <Gradient
        isSecondary={isSecondary}
        customOffset={event?.customizations?.bannerGradientOffset ?? 0}
      />
      {isSecondary && <Image image={imageBanner || image} />}
    </Wrapper>
  );
};

const TopGradient = styled.div`
  background: ${(props) => props.theme.branding.event.colors.header};
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  z-index: 5;
`;

interface DividerProps {
  color?: string;
}

const Divider = styled.div<DividerProps>`
  height: 2px;
  width: 156px;
  background-color: ${(props) => props.theme.branding.event.colors.divider};
  flex-shrink: 0;
  margin-bottom: 16px;
`;

const Wrapper = styled(FlexColumn)`
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 2;
  ${(props) =>
    props.isSecondary
      ? css`
          flex: 0 0 414px;
        `
      : css`
          flex: 0 0 164px;
        `};
  ${(props) =>
    props.theme.branding.event?.bannerHeight &&
    css`
      flex: 0 0 ${props.theme.branding.event?.bannerHeight}px;
    `};
  ${H3} {
    color: ${(props) => props.theme.branding.event.colors.artist};
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 8px;
  }
  ${H4} {
    color: ${(props) => props.theme.branding.event.colors.venue};
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 300;
    line-height: 19px;
    margin-bottom: 6px;
  }
  ${Small} {
    color: ${(props) => props.theme.branding.event.colors.date};
    font-size: 11px;
    line-height: 14px;
  }
`;

const IconMap = styled(MapIcon)`
  margin-right: 5px;
  flex: 0 0 17px;
  width: 17px;
  height: 17px;
`;

const Content = styled(FlexColumn)`
  padding: 0 16px 24px 16px;
  position: relative;
  z-index: 3;
  max-width: 600px;
  width: 100%;
`;

interface GradientProps {
  customOffset: number;
  isSecondary?: boolean;
}

const Gradient = styled.div<GradientProps>`
  background-image: ${(props) => `linear-gradient(
    180deg,
    ${
      props.isSecondary
        ? `${props.theme.branding.event.colors.page}00`
        : getShades(props.theme.branding.event.colors.page).background
    } ${props.theme.branding.event.bannerGradientOffset}%,
    ${props.theme.branding.event.colors.page} 100%
  )`};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

interface ImageProps {
  image: string;
}

const Image = styled.div<ImageProps>`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center top;
  margin: -8px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  z-index: 1;

  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 600px;
    left: initial;
  }
`;
