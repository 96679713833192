import { FC, createContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useUser } from "auth";
import { useAuthContext } from "./AuthContext";

interface EventParams {
  label?: string;
  eventId?: string;
  artistName?: string;
}

interface LogClickParams extends EventParams {
  action: string;
}

interface LogPaymentSuccessParams extends EventParams {
  label: string;
  value?: number;
  amount: number;
}

interface LogLeadFormSubmission extends EventParams {
  label: string;
}

interface LogSongRequestPlayed extends EventParams {
  label: string;
}

interface ContextInterface {
  logClick: (logClickParams: LogClickParams) => void;
  logPaymentSuccess: (logPaymentSuccessParams: LogPaymentSuccessParams) => void;
  logLeadFormSubmission: (
    logLeadFormSubmissionParams: LogLeadFormSubmission
  ) => void;
  logSongRequestPlayed: (
    logSongRequestPlayedParams: LogSongRequestPlayed
  ) => void;
}

export const AnalyticsContext = createContext<ContextInterface>(null);

export const AnalyticsProvider: FC = ({ children }) => {
  const { claims, user } = useUser();
  const { isAdmin } = claims || { isAdmin: false };
  const { isArtist } = useAuthContext();

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID || "GTM-KLZ7VPV",
      dataLayer: {
        user_id: user.uid,
        user_is_artist: isArtist,
        user_is_admin: isAdmin,
      },
    });
  }, []);

  useEffect(() => {
    if (user) {
      setUserId(user.uid);
    }
  }, [user]);

  useEffect(() => {
    if (isArtist) {
      setUserIsArtist();
    }
  }, [isArtist]);

  useEffect(() => {
    if (isAdmin) {
      setUserIsAdmin();
    }
  }, [isAdmin]);

  const setUserId = (uid: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "set_user_id",
        user_id: uid,
      },
    });
  };

  const setUserIsArtist = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "set_user_is_artist",
        user_is_artist: true,
      },
    });
  };

  const setUserIsAdmin = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "set_user_is_admin",
        user_is_admin: true,
      },
    });
  };

  const logClick = ({ eventId, action, label, artistName }: LogClickParams) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "button_click",
        action,
        label,
        event_id: eventId,
        artist_name: artistName,
      },
    });
  };

  const logPaymentSuccess = ({
    eventId,
    label,
    artistName,
    amount,
  }: LogPaymentSuccessParams) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "payment_success",
        label,
        event_id: eventId,
        artist_name: artistName,
        payment_amount: amount,
      },
    });
  };

  const logLeadFormSubmission = ({
    eventId,
    label,
    artistName,
  }: LogLeadFormSubmission) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "lead_gen",
        label,
        event_id: eventId,
        artist_name: artistName,
      },
    });
  };

  const logSongRequestPlayed = ({ eventId, label }: LogSongRequestPlayed) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "mark_song_as_played",
        label,
        event_id: eventId,
      },
    });
  };

  const providerValue: ContextInterface = {
    logClick,
    logPaymentSuccess,
    logLeadFormSubmission,
    logSongRequestPlayed,
  };

  return (
    <AnalyticsContext.Provider value={providerValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};
