import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { formatCurrency } from "Utils";
import { useAnalytics, useCustomerDetails } from "Hooks";
import { useFanEventContext, useFanModalContext } from ".";
import { useTimeContext } from "Context";
import { ButtonLink } from "Components";
import {
  FlexColumn,
  FlexRow,
  Modal,
  ModalContainer,
  H4,
  P,
  Label,
  Footnote,
  Input,
  Icon,
} from "notes";
import { getTimeSince } from "../Playlist";

export const ModalVote = ({ open }) => {
  return (
    <StyledModal open={open}>
      <VoteModalContent />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    padding: 16px 0 16px 0;
    width: 296px;
    min-height: initial;
    & > svg {
      display: none;
    }
  }
`;

const descriptions = (details) => ({
  repeat: (
    <>
      {details.eventArtist} played <span>{details.songName}</span>{" "}
      {details.played}. It is not yet eligible to be replayed but for $
      {details.repeatCost / 100} you can get it added back top of the list.
    </>
  ),
  vote: (
    <>
      Want to hear <span>{details.songName}</span>? Put some money behind the
      song to get it closer to the top of {details.eventArtist}'s requested
      songs list!
    </>
  ),
});

export const VoteModalContent = () => {
  const { logClick } = useAnalytics();
  const { setModalState, modalState, setPaymentState } = useFanModalContext();
  const [bid, setBid] = useState("");
  const [error, setError] = useState(null);

  const { event, playlist, topVoteRequestAccumAmount } = useFanEventContext();
  const { time } = useTimeContext();
  const { repeatCost, artistName: eventArtist, minVoteAmount } = event;

  const requestData = modalState?.vote;
  const { songId, modalTitle } = requestData;

  const {
    title: songName,
    playedAt,
    accumAmount,
  } = playlist?.find(({ _id }) => _id === songId);

  const handleClose = () => {
    setModalState(null);
    setBid("");
    setError(null);
  };

  const customerDetails = useCustomerDetails();

  const isRepeat = playedAt?.length > 0;
  const requestType = isRepeat ? "repeat" : "vote";
  let amountDiff = topVoteRequestAccumAmount / 100 - accumAmount / 100;

  const { minutes, hours } = getTimeSince(playedAt[playedAt?.length - 1], time);
  const playedMessage =
    minutes > 119
      ? `${hours} hours ago`
      : minutes > 59
      ? `1 hour and ${minutes - 60} min. ago`
      : minutes < 1
      ? "less than 1 min. ago"
      : `${minutes} min. ago`;

  const description = descriptions({
    eventArtist,
    songName,
    played: playedMessage,
    repeatCost: repeatCost ?? 150,
  });

  const minBidMessage = `The minimum bid to vote on a song is $${
    (minVoteAmount ?? 100) / 100
  }.00`;

  const handleSubmit = () => {
    logClick({
      action: "submitting_vote_bid",
      eventId: event._id,
    });
    const numberValue = typeof bid === "string" ? parseFloat(bid) : bid;
    const amount = parseInt((numberValue || 0).toFixed(2));
    const isWholeNumber = bid && numberValue % 1 === 0;
    if (bid && isWholeNumber && numberValue >= (minVoteAmount ?? 100) / 100) {
      setPaymentState({
        paymentForm: {
          amount,
          songId,
          eventId: event?._id,
          type: "vote",
          ...customerDetails,
        },
      });
      handleClose();
    } else {
      logClick({
        action: "error_submitting_vote_bid",
        eventId: event._id,
      });
      setError({
        open: true,
        message: !isWholeNumber
          ? "Bids must be in increments of $1.00"
          : minBidMessage,
      });
    }
  };

  const inputRef = useRef<any>();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  return (
    <>
      <Wrapper>
        <H4>{modalTitle ?? "Vote On A Song"}</H4>
        <P>{description[requestType]}</P>
        <FlexRow yCenter>
          <Label>SONG</Label>
          <H4>{songName}</H4>
        </FlexRow>
        <FlexRow yCenter>
          <Label>BID</Label>
          <StyledInput
            leftIcon={<Icon form name="USD" />}
            value={bid}
            min={minVoteAmount / 100}
            step={1}
            onChange={(value) => {
              setBid(value);
              setError(null);
            }}
            error={error}
            style={{ flexGrow: 0, width: "100px" }}
            ref={inputRef}
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
          />
        </FlexRow>
        {error?.open && <Footnote>{error?.message}</Footnote>}
        {isRepeat ? (
          <Footnote>
            For {formatCurrency(repeatCost ?? 150)} this song can added back to
            the artist's top requests!
          </Footnote>
        ) : amountDiff !== 0 && isFinite(amountDiff) ? (
          <Footnote>
            This song is $
            {minVoteAmount / 100 > amountDiff + 1
              ? minVoteAmount / 100
              : amountDiff + 1}
            .00 away from being at the top of the list!
          </Footnote>
        ) : (
          ""
        )}
      </Wrapper>
      <Actions xEnd>
        <ButtonLink type="link" onClick={handleClose}>
          Cancel
        </ButtonLink>
        <ButtonLink type="link" onClick={handleSubmit}>
          Submit
        </ButtonLink>
      </Actions>
    </>
  );
};

const Wrapper = styled(FlexColumn)`
  & > ${H4} {
    font-weight: 600;
    margin-bottom: 4px;
    padding: 0 0 0 24px;
  }
  ${P} {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 24px;
    padding: 0 24px 0 24px;
    span {
      font-weight: 600;
    }
  }
  ${Label} {
    color: ${(props) => props.theme.palette.gray.primary};
    line-height: 19px;
    font-weight: 400;
    width: 48px;
    flex-grow: 1;
  }
  & > ${FlexRow}:first-of-type {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
    padding: 16px 24px 16px 8px;
    margin-left: 24px;
    & > ${H4} {
      line-height: 22px;
      font-weight: 700;
      text-align: right;
      width: 100%;
      max-width: 174px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  & > ${FlexRow}:nth-of-type(2) {
    padding: 8px 24px 8px 8px;
    margin-left: 24px;
  }
  & > ${Footnote} {
    padding-left: 8px;
    padding-right: 24px;
    margin-left: 24px;
    margin-bottom: 12px;
  }
`;

const Actions = styled(FlexRow)`
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 16px 24px 0 0;
  button + button {
    margin-left: 16px;
  }
`;

const StyledInput = styled(Input)`
  text-align: right;
`;
