import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAnalytics, useCustomerDetails } from "Hooks";
import { useFanEventContext, useFanModalContext } from ".";
import {
  FlexColumn,
  FlexRow,
  Modal,
  ModalContainer,
  H4,
  P,
  Label,
  Footnote,
  Input,
  Icon,
} from "notes";
import { ButtonLink } from "Components";

export const ModalEncore = ({ open }) => {
  return (
    <StyledModal open={open}>
      <EncoreModalContent />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    padding: 16px 0 16px 0;
    width: 296px;
    min-height: initial;
    & > svg {
      display: none;
    }
  }
`;

const EncoreModalContent = () => {
  const { logClick } = useAnalytics();
  const { setModalState, modalState, setPaymentState } = useFanModalContext();
  const [bid, setBid] = useState("");
  const [error, setError] = useState(null);

  const { event, playlist } = useFanEventContext();
  const { minVoteAmount } = event;

  const requestData = modalState?.encoreModal;
  const { songId, modalTitle } = requestData;

  const { title: songName } = playlist?.find(({ _id }) => _id === songId);

  const handleClose = () => {
    setModalState(null);
    setBid("");
    setError(null);
  };

  const customerDetails = useCustomerDetails();

  const minBidMessage = `The minimum bid to vote on a song is $${
    (minVoteAmount ?? 100) / 100
  }.00`;

  const handleSubmit = () => {
    logClick({
      action: "submitting_encore_bid",
      eventId: event._id,
    });
    const numberValue = typeof bid === "string" ? parseFloat(bid) : bid;
    const amount = parseInt((numberValue || 0).toFixed(2));
    const isWholeNumber = bid && numberValue % 1 === 0;
    if (
      minVoteAmount === 0 ||
      (bid && isWholeNumber && numberValue >= (minVoteAmount ?? 500) / 100)
    ) {
      setPaymentState({
        paymentForm: {
          amount,
          songId,
          eventId: event?._id,
          type: "encore",
          ...customerDetails,
        },
      });
      handleClose();
    } else {
      logClick({
        action: "error_submitting_encore_bid",
        eventId: event._id,
      });
      setError({
        open: true,
        message: !isWholeNumber
          ? "Bids must be in increments of $1.00"
          : minBidMessage,
      });
    }
  };

  const inputRef = useRef<any>();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  return (
    <>
      <Wrapper>
        <H4>{modalTitle ?? "Cast Your Vote!"}</H4>
        <P>
          Want to hear <span>{songName}</span> for the encore? Put some money
          behind the song to get it closer to the top of the list!
        </P>
        <FlexRow yCenter>
          <Label>SONG</Label>
          <H4>{songName}</H4>
        </FlexRow>
        <FlexRow yCenter>
          <Label>BID</Label>
          <StyledInput
            leftIcon={<Icon form name="USD" />}
            value={bid}
            min={minVoteAmount / 100}
            step={1}
            onChange={(value) => {
              setBid(value);
              setError(null);
            }}
            error={error}
            style={{ flexGrow: 0, width: "100px" }}
            ref={inputRef}
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
          />
        </FlexRow>
        {error?.open && <Footnote>{error?.message}</Footnote>}
      </Wrapper>
      <Actions xEnd>
        <ButtonLink onClick={handleClose}>Cancel</ButtonLink>
        <ButtonLink onClick={handleSubmit}>Submit</ButtonLink>
      </Actions>
    </>
  );
};

const Wrapper = styled(FlexColumn)`
  & > ${H4} {
    font-weight: 600;
    margin-bottom: 16px;
    padding: 0 0 0 24px;
  }
  ${P} {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 24px;
    padding: 0 24px 0 24px;
    span {
      font-weight: 600;
    }
  }
  ${Label} {
    color: ${(props) => props.theme.palette.gray.primary};
    line-height: 19px;
    font-weight: 400;
    width: 48px;
    flex-grow: 1;
  }
  & > ${FlexRow}:first-of-type {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
    padding: 16px 24px 16px 8px;
    margin-left: 24px;
    & > ${H4} {
      line-height: 22px;
      font-weight: 700;
      text-align: right;
      width: 100%;
      max-width: 174px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  & > ${FlexRow}:nth-of-type(2) {
    padding: 8px 24px 8px 8px;
    margin-left: 24px;
  }
  & > ${Footnote} {
    padding-left: 8px;
    padding-right: 24px;
    margin-left: 24px;
    margin-bottom: 12px;
  }
`;

const Actions = styled(FlexRow)`
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 16px 24px 0 0;
  button + button {
    margin-left: 16px;
  }
`;

const StyledInput = styled(Input)`
  text-align: right;
`;
