const assetPath = process.env.REACT_APP_ASSETS_PATH;
export const defaultBrandingContent = {
  brand: "NextSong",
  logo: `${assetPath}/nextsong/logos/logo-nextsong-white3.png`,
  logoAlt: `${assetPath}/nextsong/logos/logo-nextsong-gradient3.png`,
  googleFontUrl: `https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700;800;900&family=Work+Sans:wght@500;700;800;900&display=swap`,
  landing: {
    title: "JOIN YOUR SHOW NOW",
    button: "Use Current Location",
    buttonLoading: "Finding Your Show",
    blocks: [
      {
        image: `${assetPath}/nextsong/8KCMtnEBpEiuMEXBadazhA.jpg`,
        title: "WHAT IS NEXTSONG?",
        body: "NextSong is interactive live music. Request a song or just show your support to artists playing your local venue!",
        button: "Find a Show!",
        link: "scroll-top",
      },
      {
        image: `${assetPath}/nextsong/umK-FFbjLU6nE1zgmvu2RQ.jpg`,
        title: "ARE YOU AN ARTIST?",
        body: "Let us know if you'd like to use Next Song at your show.",
        button: "Get Started!",
        link: "/artists",
      },
    ],
  },
  lobby: {
    joinButton: "Join Show",
    cancelButton: "Different Show",
    // splashImage: "https://stage.set.live/assets/a1fomT0clihkhkYZIednsK.png",
    disableAvatar: false,
    disableText: false,
  },
  footer: {
    copyright: `&copy; 2022 NextSong`,
    termsOfUseUrl: "/terms",
    privacyPolicyUrl: "/privacy",
  },
};

export const defaultBrandingStyles = {
  headerFont: "Work Sans, sans-serif",
  bodyFont: "Overpass, sans-serif",
  colors: {
    action: "#7C60D9",
    actionLabel: "#ffffff",
    actionSecondary: "#ffffff",
    actionSecondaryLabel: "#222222",
    actionSecondaryBorder: "#596266",
    input: {
      background: "#ffffff",
      border: "#596266",
      text: "#222222",
      font: "Overpass",
      placeholder: "#596266",
      placeholderFont: "Overpass",
    },
  },
  landing: {
    colors: {
      button: "rgba(255,255,255,0)",
      buttonBorder: "2px solid #ffffff",
      buttonText: "#ffffff",
      headerActions: "#ffffff",
      title: "#a7dbda",
      page: "linear-gradient(45deg, #7c60d9 0%, #39a4a1 100%)",
      blocks: {
        title: "#2b7a78",
        body: "#333",
        background: "#fff",
      },
      copyright: "#ffffff85",
      footerLinks: "#ffffff",
    },
  },
  lobby: {
    colors: {
      header: "linear-gradient(45deg, #7c60d9 0%, #39a4a1 100%)",
      headerActions: "#ffffff",
      page: "#fff",
      label: "#A6AEB2",
      artist: "#222",
      venue: "#222",
      date: "#596266",
      legal: "#596266",
      copyright: "#a6aeb2",
      footerLinks: "#596266",
    },
  },
  event: {
    bannerGradientOffset: 60,
    disableImageBlur: false,
    colors: {
      header: "linear-gradient(0deg, #0000 0%, #00000085 100%)",
      headerActions: "#ffffff",
      page: "#000000",
      body: "#ffffff",
      artist: "#ffffff",
      venue: "#ffffff",
      date: "#A6AEB2",
      divider: "#ff8c00",
      title: "#ffeaad",
      description: "#a6aeb2",
      caption: "#a6aeb2",
      listTitle: "#ffffff",
      listSubtitle: "#a6aeb2",
      listDivider: "#59626650",
      progressBar: "linear-gradient(225deg, #b4ec51 0%, #429321 100%)",
      progressBarBackground: "#59626650",
      tabs: {
        activeBackground: "#f7f7f5",
        activeText: "#222222",
        inactiveBackground: "#333333",
        inactiveText: "#f7f7f5",
      },
    },
  },
};
